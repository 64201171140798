import { mkNode, removeNode } from 'utils';
import { LocalData } from 'exam-service' 
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerResources, Layout, AnswerKey, AnswerValue } from 'question-base';
import { Lightbox } from 'lightbox';
import { Dropdown } from 'component-dropdown';

type Option = {
    value: string;
    backendId?: string;
} | null;

function isOption(opt: unknown): opt is {value: string, backendId?: string} {
    if (typeof opt === 'object' && opt != null) {
        const obj = opt as {[opt:string]:unknown};
        return typeof obj.value === 'string' && (
            typeof obj.backendId === 'undefined' ||
            typeof obj.backendId === 'string'
        );
    }
    return false;
}

/** Dropdown question textarea UI */
export class QuestionDropdown extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    private dropdown: Dropdown;
    
    private answer: Option;
    private updateVisibility: () => void;
    private values: ({value: string, backendId: string}|null)[] = [];

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(
        updateVisibility: () => void,
        context: QuestionContext,
        qno: number,
        ano: number, 
        backendQid: number,
        backendAid: number,
        showNumber: boolean,
        label: string,
        frag: DocumentFragment,
        options: string[],
        optionDetails: PractiqueNet.ExamJson.Definitions.AnswerOptionDetails[],
        lightbox: Lightbox,
        isRosceCandidate: boolean,
        indent?: number,
        visibilityExpression?: Expr,
        optionOrder?: number[],
        notes?: string,
        resources?: AnswerResources
    ) {
        super(context, frag, qno, ano, backendQid, backendAid, showNumber, true, label, lightbox, isRosceCandidate, notes, resources, undefined, showNumber ? Layout.CompactFloat : Layout.Default);
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`; 
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        this.dropdown = new Dropdown(this.answerItem, true);
        this.dropdown.addOption('Not Answered');
        this.values.push(null);
        for (let i = 0; i < options.length; ++i) {
            const idx = (optionOrder) ? optionOrder[i] : i;
            const value = options[idx];
            const details = optionDetails[idx];
            this.dropdown.addOption(value, details.description);
            if (value && details.backend_id) {
                this.values.push({value, backendId: String(details.backend_id)});
            } else {
                this.values.push(null);
            }
        }
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        this.answer = null;
    }

    public updateDisable(): void {
        super.updateDisable();
        this.dropdown.disable(this.isDisabled());
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            if (response && isOption(response.answer)) {
                this.dropdown.selectByValue(response.answer.value);
            } else {
                this.dropdown.select(0);
            }    
            this.answer = this.values[this.dropdown.selectedIndex()];
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.answerItem.addEventListener('dropdown-select', this.handleSelect);
    }

    /** Get the answer value */
    public getValue(): string {
        return this.answer?.value ?? '';
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.answerItem.removeEventListener('dropdown-select', this.handleSelect);
        super.destroy();
    }

    public focus(): void {
        this.dropdown.focus();
    }

    public getAnswer(): AnswerKey & AnswerValue {
        return {qno: this.qno, ano: this.ano,  answer: this.answer};
    }

    private readonly handleSelect = async (event: Event) => {
        console.log('HANDLE_SELECT', event);
        if (event instanceof CustomEvent && typeof event.detail === 'number') {
            try {
                this.answer = this.values[event.detail];
                await this.context.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.answer});
            } catch(err) {
                console.error(String(err));
            }
            this.updateVisibility();
        }
    }
}


registerAnswerType({
    name: 'Dropdown',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer, n: number): boolean => {
        return (answer.type.toLowerCase() === 'sba' && n > 1) || answer.type.toLowerCase() === 'dropdown';
    },
    makeAnswer: (
        pos: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerDiscrete,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRosceCandidate: boolean,
    ): Question => {
        return new QuestionDropdown(
            updateVisibility,
            context,
            pos,
            ano,
            question.manifest.backend_id,
            answer.backend_id,
            question.manifest.answers.length > 1,
            answer.label,
            frag,
            answer.options,
            answer.optionDetails ?? [],
            lightbox,
            isRosceCandidate,
            answer.indent,
            answer.visible,
            answer.candidateToOptionOrder?.[context.candidateCid],
            answer.notes,
            question.answersResources[ano],
        );
    }
});
