import { Img, IType } from 'image-base';
import { mkNode, /*scrollRangeIntoView,*/ removeNode } from 'utils';
import { Thumbnail, ThumbnailObserver } from 'thumbnail-base';
import { ResourceThumbnail } from 'thumbnail-image';
import { ResourceVideo } from 'thumbnail-video';
import { ResourceAudio } from 'thumbnail-audio';
import { Lightbox } from 'lightbox';

export interface ThumbnailContext {
    fullscreenParent: Element;
    scrollContainer: Element;
    sizeReference: Element;
    getImageBegin(): Promise<void>;
    getImageFrame(image: Img, frame: number): Promise<ArrayBuffer>;
    getImageEnd(): Promise<void>;
    getNavigating(): boolean;
    setNavigating(isNavigating: boolean): void;
    isRosceCandidate: boolean;
}

export class ThumbnailViewer {
    private context: ThumbnailContext;
    private thumbnailPanel: HTMLDivElement;
    private thumbnails: Thumbnail[] = [];
    private lightbox: Lightbox;
    
    constructor(context: ThumbnailContext, parent: Node, images: Img[], lightbox: Lightbox) {
        this.context = context;
        this.lightbox = lightbox;
        this.thumbnailPanel = mkNode('div', {className: 'thumbs', parent: parent});
        //this.viewerPanel = mkNode('div', {className: 'viewer-panel', parent: parent});
        for (let j = 0; j < images.length; ++j) {
            let thumbnail;
            switch (images[j].iType) {
                case IType.Std:
                case IType.Dicom:
                case IType.Tiff:
                case IType.Pdf:
                    console.log('STANDARD IMAGE');
                    thumbnail = new ResourceThumbnail(images[j], this.thumbnailPanel, !context.isRosceCandidate);
                    break;
                case IType.Video:
                    thumbnail = new ResourceVideo(images[j], this.thumbnailPanel, !context.isRosceCandidate);
                    break;
                case IType.Audio:
                    thumbnail = new ResourceAudio(images[j], this.thumbnailPanel, !context.isRosceCandidate);
                    break;
                default:
                    continue;
            }
            thumbnail.hide(context.isRosceCandidate);
            this.thumbnails.push(thumbnail);
        }
    }

    public async loadResources(buffers: (ArrayBuffer|null)[]): Promise<void> {
        console.log('THUMBNAILS LOAD');
        for (let j = 0; j < this.thumbnails.length; ++j) {
            const thumbnail = this.thumbnails[j];
            if (thumbnail instanceof ResourceThumbnail) {
                await this.thumbnails[j].load(buffers[j]);
            } else if ((thumbnail instanceof ResourceVideo) || (thumbnail instanceof ResourceAudio)) {
                this.context.getImageBegin();
                const data = await this.context.getImageFrame(thumbnail.image, 0);
                this.context.getImageEnd();
                await this.thumbnails[j].load(data);
            }
        }
        this.thumbnailPanel.addEventListener('click', this.handleClick);
    }

    public destroy(): void {
        this.thumbnailPanel.removeEventListener('click', this.handleClick);
        removeNode(this.thumbnailPanel);
        for (const thumbnail of this.thumbnails) {
            thumbnail.destroy();
        }
    }

    public disableShowHide(disable: boolean): void {
        for (const thumbnail of this.thumbnails) {
            thumbnail.disableShowHide(disable);
        }
    }

    public disabled(disable: boolean): void {
        for (const thumbnail of this.thumbnails) {
            thumbnail.disable(disable);
        }
    }

    public getStatus(status: {id: string, released: boolean}[]): void {
        for (const thumbnail of this.thumbnails) {
            status.push({id: thumbnail.getImage().id, released: thumbnail.getStatus()});
        }
    }

    public setStatus(status: {id?: string, released: boolean}) {
        for (const thumbnail of this.thumbnails) {
            const image = thumbnail.getImage();
            if (status.id === undefined || image.id === status.id) {
                if (this.context.isRosceCandidate && image.distribution === 'restricted') {
                    thumbnail.hide(!status.released);
                    if (!status.released) {
                        this.lightbox.close(image.id);
                    }
                } else {
                    thumbnail.setStatus(status.released);
                }
                if (status.id !== undefined) {
                    break;
                }
            }
        }
    }

    public addObserver(observer: ThumbnailObserver) {
        for (const thumbnail of this.thumbnails) {
            thumbnail.addObserver(observer);
        }
    }

    private readonly handleClick = async (event: MouseEvent): Promise<void> => {
        if (this.context.getNavigating()) {
            return;
        }
        for (const thumbnail of this.thumbnails) {
            if (event.target instanceof Node && thumbnail.contains({x :event.clientX, y: event.clientY}) && !thumbnail.isDisabled()) {
                console.log('THUMBNAIL_CLICK');
                this.context.setNavigating(true);
                try {
                    await thumbnail.open(() => {
                        console.log('OPEN LIGHTBOX', this.lightbox);
                        return this.lightbox.open();
                    });
                } finally {
                    this.context.setNavigating(false);
                }
                    /*this.viewer = new DicomViewer({
                        parent: this.viewerPanel,
                        scrollContainer: this.context.scrollContainer,
                        fullscreenParent: this.context.fullscreenParent,
                        after: null,
                        sizeReference: this.context.sizeReference,
                        getImageBegin: this.context.getImageBegin,
                        getImageFrame: async (image: Img, frame: number): Promise<ArrayBuffer> => {
                            const data = await this.context.getImageFrame(image, frame);
                            console.log('THUMB GOT FRAME');
                            return data;
                        },
                        getImageEnd: this.context.getImageEnd,
                        getNavigating: this.context.getNavigating,
                        noMouse: this.context.noMouse,
                        window: window,
                        forceFullscreen: false, // isTouchDevice,
                    });
                    this.viewer.onclose = (): void => {
                        thumbnail.select(false);
                        scrollRangeIntoView(this.thumbnailPanel);
                        //this.viewer = undefined;
                    };
                    return this.viewer;*/
                //});
            }
        }
    }
}
