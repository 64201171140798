import { registerLanguage, TranslationArgs } from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('English', {
    LOCALE: 'en-GB',
    LOGIN_CANDIDATE_ID_LABEL: 'ID',
    LOGIN_CANDIDATE_ID_HINT: 'Login ID',
    LOGIN_PASSWORD_LABEL: 'PW',
    LOGIN_PASSWORD_HINT: 'Password',
    LOGIN_BUTTON: 'Login',
    
    CHOOSE_INSTRUCTIONS: ({cid}) => `${cid ? `<p>You are logged in as user <b>${cid}</b>.</p>` : ''}`,
    CHOOSE_PROCTORED_EXAMITY: `<p><b>INSTRUCTIONS</b></p><p>Please click on <i>Start&nbsp;proctoring</i> to access the EXAMITY platform<ul><li>To create your profile</li><li>To connect to your proctor and start your exam</li><li>If you are redirected here and your exam has not yet opened, please wait and you will be connected automatically at the start time.</li></ul><p>`,
    CHOOSE_NOT_PROCTORED: `<p><b>INSTRUCTIONS</b></p><p>Please wait, you will be automatically connected when your exam is opened.</p>`,
    CHOOSE_NOT_PROCTORED_ALT: `<p><b>INSTRUCTIONS</b></p><p>Enter the exam PIN you have been provided with, choose the exam and session you are taking, and then click <i>Take chosen exam</i> to start</p>`,
    CHOOSE_SUPPORT: `<p><b>SUPPORT</b></p><p>If you are not connected after the start time of your exam, please contact your exam support team.</p>`,
    CHOOSE_SUPPORT_ALT: `<p><b>SUPPORT</b></p><p>If you have difficulties starting the exam, please contact your exam support team.</p>`,
    CHOOSE_PIN_LABEL: 'PIN',
    CHOOSE_PIN_HINT: 'Offline Start PIN',
    CHOOSE_PIN_ALT: 'Start PIN',
    CHOOSE_EXAM_BUTTON: 'Take chosen exam',
    CHOOSE_LOGOUT_BUTTON: 'Logout',
    CHOOSE_PROCTOR_BUTTON: 'Start proctoring',
    CHOOSE_EXAM_HINT: 'Choose exam to take...',

    ERROR_NETWORK: 'Network error',
    ERROR_USER_PASS: 'Login ID or Password is incorrect',
    ERROR_CONNECTION_TIMEOUT: 'Connection timeout',
    ERROR_HTTP: ({code}:TranslationArgs) => `Server error ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_UNKNOWN: ({message}:TranslationArgs) => `Error ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "You have run out of storage space. Please make more space available, or move to a different device.",
    ERROR_PIN: 'Incorrect PIN, or wrong exam chosen',
    ERROR_CANDIDATE_NOT_FOUND: 'User ID entered incorrectly, or is not registered for this exam.',
    ERROR_RESEND: ({err}:TranslationArgs) => `${err}\n\nUnsubmitted answers are usually caused by network problems, you should check your WiFi, router and broadband connection are all working and then try submitting again. If you are having problems you should contact exam support.\n`,

    DOWNLOAD_TITLE: ({downloadCount}) => `Downloading ${downloadCount} exam${plural(downloadCount) ? 's' : ''}, please wait...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Downloading can take several minutes depending on your network connectivity</p>${(freeSpace !== undefined) ? `<p>[<b>${freeSpace}MB</b> free storage remaining]</p>` : ''}`,

    PREPARING_TITLE: 'Preparing exam, please wait...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Preparing can take several minutes depending on your computer specification<p>${(freeSpace !== undefined) ? `<p>[<b>${freeSpace}MB</b> free storage remaining]</p>` : ''}`,
    
    CONTROL_OVERVIEW: 'OVERVIEW',
    CONTROL_LANGUAGE: 'LANGUAGE',
    CONTROL_FINISH: 'FINISH',
    CONTROL_CALCULATOR: 'CALCULATOR',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'USER ID',
    CONTROL_PREVIOUS: 'PREVIOUS',
    CONTROL_NEXT: 'NEXT',
    CONTROL_COLOUR: 'COLOUR',

    NOTIFICATION_CONNECT: '<center>Click the <b>CONNECT</b> button above to start.</center>',
    NO_CANDIDATE: 'No candidate for this round.',

    MEETING_POOR_CONNECTION: '<center><b>Poor connection</b> you may have audio and video quality issues.</center>',
    MEETING_STATUS_ERROR: ({err}) => `<center><b>Meeting exit status</b> ${err}</center>`,

    TIMER_REMAINING: 'TIME REMAINING',
    TIMER_ELAPSED: 'ELAPSED TIME',
    TIMER_ROUND_REMAINING: 'ROUND REMAINING',

    OVERVIEW_TITLE: 'Overview',
    RESOURCE_RESTRICTED_SHOW: 'Show',
    RESOURCE_RESTRICTED_HIDE: 'Hide',

    ANSWER_TITLE: 'Answer',
    ANSWER_FLAG_TIP: 'Flag this question',
    SBA_ANSWER_TIP: 'Select this answer',
    SBA_ELIMINATE_TIP: 'Eliminate this answer',

    PAUSED_TITLE: 'Exam paused',
    PAUSED_DESCRIPTION: '<p>The exam has been <strong>paused</strong> remotely by an administrator.<p>',

    STOPPED_TITLE: 'Exam Finished',
    STOPPED_CLEANUP: '<p>Cleaning up, please wait...<p>',

    FINISH_TITLE: 'Ready to finish?',
    FINISH_DESCRIPTION: 'You should click <i>Submit exam</i> below before closing the tab or window.</p>',
    FINISH_ANSWERED: '<p>You have answered all the questions.</p>',
    FINISH_UNANSWERED: ({unanswered}) => `<p>You have <strong>${unanswered}</strong> unanswered question${plural(unanswered) ? 's' : ''}.</p>`,
    FINISH_SUBMITTED: '<p><b>All of your answers have been submitted to the server.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>You have <strong>${unsubmitted}</strong> answer${plural(unsubmitted) ? 's' : ''} that ${plural(unsubmitted) ? 'are' : 'is'} not submitted to the server.</p><p>It is safe to continue the exam as answers will be stored on your device.</p>`,
    FINISH_CONTINUE: 'Continue exam',
    FINISH_NOW: 'Submit exam',
});
