import { makeElements, removeChildren, NodeSpecObject, safeJsonParse } from './utils';
import { ExamMap, Proctors, getProctors, proctorExamity } from './exam-service';
import { ExamId } from './utils-db';
import { translate } from './utils-lang';
import { urlWithCredentials } from 'utils-net';
import { ReconnectingEventSource } from 'utils-events';
import { configSolidNtrlButton, configSolidSafeButton } from 'exam-accessibility';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface EventSourceEventMap {
        'exam-started': MessageEvent;
        'examlist-changed': MessageEvent;
    }
}

interface ExamSelectUi {
    examSelect: HTMLDivElement;
    examLogo: HTMLDivElement;
    examText: HTMLDivElement;
    examChoiceDiv: HTMLDivElement;
    examChoice: HTMLSelectElement;
    examChoiceIcon: HTMLSpanElement;
    examTextGroup: HTMLDivElement;
    examPinLabel: HTMLDivElement;
    examPin: HTMLInputElement;
    pinError: HTMLDivElement;
    examCidLabel: HTMLDivElement;
    examCid: HTMLInputElement;
    examProctor: HTMLInputElement;
    examGo: HTMLInputElement;
    examBack: HTMLInputElement;
}

function examSelectUi(manualStart: boolean): NodeSpecObject<ExamSelectUi> {
    return {
        examSelect: { elem: 'div', className: 'login-panel config-background' },
        examLogo: {
            elem: 'div', className: 'logo-panel', parent: 'examSelect', children: [
                { elem: 'img', className: 'client-logo', attrib: { draggable: 'false', src: '/static/images/client-logo.png' } }
            ]
        },
        examText: {
            elem: 'div', className: 'choose-instructions', parent: 'examSelect'
        },
        examProctor: {
            elem: 'input', className: configSolidSafeButton, parent: 'examSelect', style: {marginBottom: '4.8rem'},
            attrib: { type: 'button', value: translate('CHOOSE_PROCTOR_BUTTON') }
        },
        examTextGroup: {
            elem: 'div', className: 'exam-text-group config-background',
            parent: 'examSelect'
        },
        examPinLabel: {
            elem: 'div', className: 'exam-label', parent: 'examTextGroup',
            children: [
                { elem: 'text', text: translate('CHOOSE_PIN_LABEL') }
            ]
        },
        examPin: {
            elem: 'input', parent: 'examPinLabel',
            attrib: { type: 'text', autocorrect: 'off', autocapitalize: 'none', placeholder: translate(manualStart ? 'CHOOSE_PIN_ALT' : 'CHOOSE_PIN_HINT') }
        },
        pinError: {
            elem: 'div', parent: 'examSelect', className: 'exam-error-text', style: {
                marginBottom: '16px',
            }
        },
        examCidLabel: {
            elem: 'div', className: 'exam-label', parent: 'examTextGroup',
            children: [
                { elem: 'text', text: 'CID:' }
            ]
        },
        examCid: {
            elem: 'input', parent: 'examCidLabel',
            attrib: { type: 'text', autocorrect: 'off', autocapitalize: 'none', placeholder: 'Candidate ID No.' }
        },
        examChoiceDiv: {elem: 'div', parent: 'examSelect', style: {
            position: 'relative',
            marginBottom: '16px',
            padding: '0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'stretch',
        }},
        examChoice: { elem: 'select', className: 'exam-choice ' + configSolidNtrlButton, parent: 'examChoiceDiv'},
        examChoiceIcon: { elem: 'icon', icon: faCaretDown, className: 'config-ntrl', parent: 'examChoiceDiv', style: {
            display: 'block',
            position: 'absolute',
            right: '0', 
            top: '0',
            bottom: '0',
            padding: '0 16px 0 8px',
            fontSize: '28px',
            backgroundColor: 'inherit',
            margin: '1.5px 1.5px 1.5px 0',
        }},
        examGo: {
            elem: 'input', className: configSolidSafeButton, parent: 'examSelect',
            attrib: { type: 'button', value: translate('CHOOSE_EXAM_BUTTON') }
        },
        examBack: {
            elem: 'input', className: configSolidNtrlButton, parent: 'examSelect', style: {marginTop: '4.8rem'},
            attrib: { type: 'button', value: translate('CHOOSE_LOGOUT_BUTTON') }
        },
    };
}

interface ExamOptionUi {
    examOption: HTMLOptionElement;
    optionText: Text;
}

const examOptionUi = {
    examOption: { elem: 'option' },
    optionText: { elem: 'text', parent: 'examOption' }
};

export interface ChoiceContext {
    parent: HTMLElement;
    badPin: boolean;
    badCid: boolean;
    examPin?: string;
    candidateId?: string;
    forceCandidateId?: string;
    exams: ExamMap;
    chosenExamId?: string;
    manualStart: boolean;
}

export interface ChosenExam {
    kind: 'exam';
    examId: ExamId;
    examPin: string;
    candidateId: string;
}

export interface Logout {
    kind: 'logout';
}

interface StartMessage {
    examid: string;
    pin: string;
    users: {[x:string]:string};
    timestamp: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isStartMessage(x: any): x is StartMessage {
    return x && typeof x === 'object' &&
        typeof x.examid === 'string' &&
        typeof x.pin === 'string' &&
        typeof x.users === 'object' &&
        typeof x.timestamp === 'number';
}

export class AssignmentViewer {
    private readonly parent: HTMLElement;
    private si: ExamSelectUi; 
    private badPin: boolean;
    private badCid: boolean;
    private forceCandidateId?: string;
    //private someProctored: boolean;
    private proctors?: Proctors;
    private eventSource: ReconnectingEventSource;
    private manualStart: boolean;

    constructor(context: ChoiceContext) {
        this.manualStart = context.manualStart;
        this.si = makeElements(examSelectUi(context.manualStart)) as ExamSelectUi;
        //this.someProctored = false;
        if (context.exams.size > 0) {
            const oi = makeElements(examOptionUi) as ExamOptionUi;
            oi.examOption.value = '';
            oi.examOption.disabled = true;
            oi.examOption.selected = true;
            oi.examOption.hidden = true;
            oi.optionText.nodeValue = translate('CHOOSE_EXAM_HINT');
            this.si.examChoice.appendChild(oi.examOption);
        }
        context.exams.forEach((ex, id): void => {
            const oi = makeElements(examOptionUi) as ExamOptionUi;
            oi.examOption.value = id;
            oi.optionText.nodeValue = ex.full_title;
            this.si.examChoice.appendChild(oi.examOption);
            if (id === context.chosenExamId || context.exams.size == 1) {
                oi.examOption.selected = true;
            }
            //this.someProctored = this.someProctored || (ex.proctored ?? false);
        });
        this.si.examChoice.style.display = 'block';

        context.parent.appendChild(this.si.examSelect);
        this.parent = context.parent;
        this.badPin = context.badPin;
        if (this.badPin) {
            this.si.examPin.className = 'navigation-text exam-pin exam-error';
            this.si.pinError.textContent = translate('ERROR_PIN');
            this.si.pinError.style.display = 'block';
            this.badPin = false;
        } else {
            this.si.examPin.className = 'navigation-text exam-pin';
            this.si.pinError.style.display = 'none';
        }
        if (context.examPin) {
            this.si.examPin.value = context.examPin;
            this.si.examChoiceDiv.style.display = 'block';
            this.si.examGo.style.display = 'block';
        } else {
            this.si.examChoiceDiv.style.display = 'none';
            this.si.examGo.style.display = 'none';
        }
        this.si.examPinLabel.style.display = 'flex';
        this.forceCandidateId = context.forceCandidateId;
        this.badCid = context.badCid;
        if (!this.forceCandidateId) {
            if (this.badCid) {
                this.si.examCid.className = 'navigation-text exam-cid exam-error';
                this.badCid = false;
            } else {
                this.si.examCid.className = 'navigation-text exam-cid';
            }
            if (context.candidateId) {
                this.si.examCid.value = context.candidateId;
            }
            this.si.examCidLabel.style.display = 'flex';
        } else {
            this.si.examCidLabel.style.display = 'none';
        }
        this.si.examProctor.style.display = 'none';
        context.parent.appendChild(this.si.examSelect);
        this.eventSource = new ReconnectingEventSource(new URL(urlWithCredentials('/app/all/events/'), window.location.origin));
        this.eventSource.addEventListener('exam-started', this.handleStartMessage);
        this.eventSource.addEventListener('examlist-changed', this.handleExamlistMessage);
    }

    destroy(): void {
        this.eventSource.removeEventListener('examlist-changed', this.handleExamlistMessage);
        this.eventSource.removeEventListener('exam-started', this.handleStartMessage);
        this.eventSource.close();
        removeChildren(this.parent);
    }

    private readonly handleStartMessage = (event: MessageEvent<string>): void => {
        const data = safeJsonParse(event.data);
        const user = this.forceCandidateId ?? this.si.examCid.value;
        if (isStartMessage(data) && user) {
            const component = data.users[user];
            if (component) {
                console.log('SSE_START', data);
                this.si.examChoice.value = data.examid + '-' + component;
                this.si.examPin.value = data.pin;
                this.si.examGo.click();
            }
        }
    }

    private readonly handleExamlistMessage = (event: MessageEvent<string>): void => {
        const data = safeJsonParse(event.data);
        console.log('SSE_CHANGE', data);
        window.location.reload();
    }

    private readonly handlePin = (): void => {
        const showChoice = this.si.examPin.value.trim().length > 0;
        const showGo = showChoice && (this.si.examChoice.value);
        this.si.examChoiceDiv.style.display = showChoice ? 'block' : 'none';
        this.si.examGo.style.display = showGo  ? 'block' : 'none';
        this.si.examPin.className = 'navigation-text exam-pin';
        this.si.pinError.style.display = 'none';  
    }

    private readonly handleChoice = (): void => {
        const showGo = this.si.examChoice.value;
        this.si.examGo.style.display = showGo  ? 'block' : 'none';
        this.si.examPin.className = 'navigation-text exam-pin';
        this.si.pinError.style.display = 'none'; 
    }

    public async getExamChoice(): Promise<ChosenExam|Logout> {
        return new Promise(async resolve => {
            if (this.si.examChoice.children.length > 0) {
                this.si.examPin.oninput = this.handlePin;
                this.si.examPin.onchange = this.handlePin;
                this.si.examChoice.onchange = this.handleChoice;
                this.si.examGo.onclick = (): void => {
                    const examPin = this.si.examPin.value.trim();
                    const candidateId = this.forceCandidateId ?? this.si.examCid.value.trim();
                    const examId = this.si.examChoice.value.trim();
                    if (examPin && candidateId && examId) {
                        this.parent.removeChild(this.si.examSelect);
                        resolve({kind: 'exam', examId, examPin, candidateId});
                    }
                };
            } else {
                this.si.examChoice.style.display = 'none';
                this.si.examGo.style.display = 'none';
                this.si.examCidLabel.style.display = 'none';
                this.si.examPinLabel.style.display = 'none'; 
            }
            const proctors = await getProctors();
            this.si.examText.innerHTML = translate('CHOOSE_INSTRUCTIONS', {cid: this.forceCandidateId}) +
             ((proctors.examity) ? translate('CHOOSE_PROCTORED_EXAMITY') : translate(this.manualStart ? 'CHOOSE_NOT_PROCTORED_ALT' : 'CHOOSE_NOT_PROCTORED')) +
             translate(this.manualStart ? 'CHOOSE_SUPPORT_ALT' : 'CHOOSE_SUPPORT');
            if (proctors.examity) {
                this.si.examProctor.style.display = 'block';
                this.si.examProctor.onclick = (): void => {
                    if (proctors.examity) {
                        proctorExamity(proctors.examity);
                    }
                };
            }
            this.si.examBack.onclick = (): void => {
                this.parent.removeChild(this.si.examSelect);
                resolve({kind: 'logout'});
            };
            // ti.download.disabled = false;
            // ti.download.onclick = (): void => {
            //     ti.download.disabled = true;
            //     wi.chooseExam.removeChild(si.examSelect);
            //     succ({ download: true });
            // };
        });
    }
}
