import { AES_GCM, Pbkdf2HmacSha256 } from 'asmcrypto.js/dist_es5/entry-export_all';
import { translate } from 'utils-lang';

declare global {
    interface Window {
        webkitIndexedDB?: typeof window.indexedDB;
        mozIndexedDB?: typeof window.indexedDB;
        oIndexedDB?: typeof window.indexedDB;
        msIndexedDB?: typeof window.indexedDB;
        shimIndexedDB?: typeof window.indexedDB;
    }
}

const IDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB ||
    window.oIndexedDB || window.msIndexedDB || window.shimIndexedDB;

if (IDB === window.shimIndexedDB) {
    console.log('WARNING: using IndexedDBShim');
}

export class StorageError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'StorageError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
} 

let db: IDBDatabase | undefined = undefined;

export function closeDatabase(): void {
    db?.close;
    db = undefined;
}

async function openDatabase(): Promise<IDBDatabase> {
    if (db) {
        return db;
    }
    return new Promise((succ, fail): void => {
        try {
            const openRequest = IDB.open('practique', 6);
            openRequest.onblocked = (): void => {
                const message = openRequest.error ? openRequest.error.toString() : 'unknown error';
                console.log('open database blocked:', message);
                fail(new StorageError(message));
            };
            openRequest.onerror = (): void => {
                const message = openRequest.error ? openRequest.error.toString() : 'unknown error';
                console.log('open database error: ', message);
                fail(new StorageError(message));
            };
            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent): void => {
                console.log('upgrade needed');
                if (event.newVersion != null && event.oldVersion < event.newVersion) {
                    console.log('upgrading database');
                    const db = openRequest.result;
                    if (db.objectStoreNames.contains('exams')) {
                        db.deleteObjectStore("exams");
                    }
                    if (db.objectStoreNames.contains('encrypted')) {
                        db.deleteObjectStore("encrypted");
                    }
                    if (db.objectStoreNames.contains('questions')) {
                        db.deleteObjectStore('questions');
                    }
                    if (db.objectStoreNames.contains('images')) {
                        db.deleteObjectStore('images');
                    }
                    if (db.objectStoreNames.contains('answers')) {
                        db.deleteObjectStore('answers');
                    }
                    if (db.objectStoreNames.contains('status')) {
                        db.deleteObjectStore('status');
                    }
                    if (db.objectStoreNames.contains('flags')) {
                        db.deleteObjectStore('flags');
                    }
                    if (db.objectStoreNames.contains('users')) {
                        db.deleteObjectStore('users');
                    }
                    if (db.objectStoreNames.contains('session')) {
                        db.deleteObjectStore('session');
                    }
                    db.createObjectStore('exams');
                    db.createObjectStore('encrypted');
                    db.createObjectStore('questions');
                    db.createObjectStore('images');
                    db.createObjectStore('answers');
                    db.createObjectStore('status');
                    db.createObjectStore('flags');
                    db.createObjectStore('users');
                    db.createObjectStore('session');
                } else {
                    throw 'A newer version of the database exists on this device, clear web storage to continue';
                }
            };
            openRequest.onsuccess = (): void => {
                db = openRequest.result;
                db.onversionchange = () => {
                    console.log('DB_VERSION_CHANGE');
                    if (db) {
                        db.close();
                        db = undefined;
                    }
                }
                db.onclose = () => {
                    console.log('DB_CLOSED');
                    db = undefined;
                }
                succ(db);
            };
        } catch (err) {
            console.error('error: openDatabase', err.message ?? err);
            fail(new StorageError(err.message));
        }
    });
}

export async function dbGet<T = unknown>(store: string, key: IDBValidKey | IDBKeyRange): Promise<T | undefined> {
    const db = await openDatabase();
    return new Promise<T>((resolve, reject): void => {
        const request = db.transaction([store], "readonly").objectStore(store).get(key);
        request.onerror = (): void => {
            reject(new StorageError(request.error?.message ?? ''));
        };
        request.onsuccess = (): void => {
            resolve(request.result);
        }
    });
}

export async function dbCursor(store: string, key: undefined | IDBValidKey | IDBKeyRange,
    fn: (cursor: IDBCursorWithValue) => void
): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const request = db.transaction([store], "readonly").objectStore(store).openCursor(key);
        request.onerror = (): void => {
            fail(new StorageError(request.error?.message ?? ''));
        };
        request.onsuccess = (): void => {
            const cursor = request.result;
            if (cursor) {
                try {
                    fn(cursor);
                    cursor['continue']();
                } catch (err) {
                    fail(err);
                }
            } else {
                succ();
            }
        };
    });
}

export async function dbPut<T>(store: string, key: IDBValidKey, value: T): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>(async (succ, fail): Promise<void> => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).put(value, key);
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(transaction.error?.message ?? ''));
        };
        transaction.oncomplete = (): void => {
            succ();
        };
    });
}

export async function dbDelete(store: string, key: IDBValidKey | IDBKeyRange): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).delete(key);
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(transaction.error?.message ?? ''));
        };
        transaction.oncomplete = (): void => {
            succ();
        };
    });
}

export async function dbClear(store: string): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).clear();
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(transaction.error?.message ?? ''));
        };
        transaction.oncomplete = (): void => {
            succ();
        }
    });
}


//----------------------------------------------------------------------------
// Stream Reader & Writer for IndexedDB

export class IDBWriter extends zip.Writer {
    private buf: Uint8Array;
    private ptrChunk: number;
    private ptrOffset: number;
    private store: string;
    private chunkSize: number;

    public constructor(store: string, chunkSize: number) {
        super();
        this.store = store;
        this.chunkSize = chunkSize;
        this.buf = new Uint8Array(chunkSize);
        this.ptrChunk = 0;
        this.ptrOffset = 0;
    }

    public init(callback: () => void): void {
        callback();
    }

    public getPtr(): number {
        return this.ptrChunk * this.chunkSize + this.ptrOffset;
    }

    public async writeUint8ArrayPromise(data: Uint8Array): Promise<void> {
        if (this.ptrOffset + data.length < this.buf.length) {
            this.buf.set(data, this.ptrOffset);
            this.ptrOffset += data.length;
        } else if (this.ptrOffset + data.length < 2 * this.buf.length) {
            const split = this.buf.length - this.ptrOffset;
            this.buf.set(data.subarray(0, split), this.ptrOffset);
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            this.buf.set(data.subarray(split), 0);
            this.ptrOffset = data.length - split;
        } else {
            let offset = this.buf.length - this.ptrOffset;
            this.buf.set(data.subarray(0, offset), this.ptrOffset);
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            while (offset + this.buf.length < data.length) {
                this.buf.set(data.subarray(offset, offset + this.buf.length), 0);
                await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
                offset += this.buf.length;
            }
            this.buf.set(data.subarray(offset), 0);
            this.ptrOffset = data.length - offset;
        }
    }

    public async writeUint8Array(data: Uint8Array, callback: () => void): Promise<void> {
        await this.writeUint8ArrayPromise(data)
        callback();
    }

    public async close(): Promise<void> {
        if (this.ptrOffset > 0) {
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            this.ptrOffset = 0;
        }
    }
}

export class IDBReader extends zip.Reader {
    private cacheId?: number;
    private cacheData?: ArrayBuffer;
    private store: string;
    private chunkSize: number

    public constructor(store: string, chunkSize: number) {
        super();
        this.store = store;
        this.chunkSize = chunkSize;
    }

    private async readBuf(chunkId: number): Promise<ArrayBuffer> {
        if (this.cacheData && chunkId === this.cacheId) {
            return this.cacheData;
        }
        const data = await dbGet<ArrayBuffer>(this.store, chunkId);
        if (data == undefined) {
            throw 'Image store key does not exist: ' + chunkId;
        }
        this.cacheData = data;
        this.cacheId = chunkId;
        return data;
    }

    public async readUint8Array(start: number, end: number): Promise<Uint8Array> {
        const startChunk = Math.floor(start / this.chunkSize);
        const startOffset = start - (startChunk * this.chunkSize);
        const endChunk = Math.floor(end / this.chunkSize);
        const endOffset = end - (endChunk * this.chunkSize);
        const length = end - start;
        //log('IDBReader readUint8Array: start chunk:', startChunk, 'offset:', startOffset, ' ->  end chunk:', endChunk, 'offset:', endOffset);

        if (startChunk === endChunk) {
            const buf1 = (await this.readBuf(startChunk)).slice(startOffset, endOffset);
            return new Uint8Array(buf1);
        } else if (startChunk + 1 === endChunk) {
            const data = new Uint8Array(length);
            const buf1 = await this.readBuf(startChunk);
            data.set(new Uint8Array(buf1).subarray(startOffset));
            const buf2 = await this.readBuf(endChunk);
            data.set(new Uint8Array(buf2).subarray(0, endOffset), this.chunkSize - startOffset);
            return data;
        } else {
            const data = new Uint8Array(length);
            const buf1 = await this.readBuf(startChunk);
            data.set(new Uint8Array(buf1).subarray(startOffset));
            let offset = this.chunkSize - startOffset;
            for (let chunk = startChunk + 1; chunk < endChunk; ++chunk) {
                const buf2 = await this.readBuf(chunk);
                data.set(new Uint8Array(buf2), offset);
                offset += this.chunkSize;
            }
            const buf3 = await this.readBuf(endChunk);
            data.set(new Uint8Array(buf3).subarray(0, endOffset), offset);
            return data;
        }
    }

    /*
    public async readBlob(start: number, end: number): Promise<Blob> {
        const startChunk = Math.floor(start / this.chunkSize);
        const startOffset = start - (startChunk * this.chunkSize);
        const endChunk = Math.floor(end / this.chunkSize);
        const endOffset = end - (endChunk * this.chunkSize);
        const length = end - start;

        if (startChunk === endChunk) {
            const buf1 = (await this.readBuf(startChunk)).slice(startOffset, endOffset);
            return new Blob([buf1]);
        } else if (startChunk + 1 === endChunk) {
            const buf1 = (await this.readBuf(startChunk)).slice(startOffset);
            const buf2 = (await this.readBuf(endChunk)).slice(0, endOffset);
            return new Blob([buf1, buf2]);
        } else {
            const bufs = [];
            bufs.push(await this.readBuf(startChunk).slice(startOffset));
            let offset = this.chunkSize - startOffset;
            for (let chunk = startChunk + 1; chunk < endChunk; ++chunk) {
                bufs.push(await this.readBuf(chunk));
            }
            bufs.push(await this.readBuf(endChunk).slice(0, endOffset));
            return new Blob(bufs);
        }
    }
    */

    public close(): void {
        this.cacheId = undefined;
        this.cacheData = undefined;
    }
}


//----------------------------------------------------------------------------
// Read Encrypted Stream from IndexedDB

// WebCrypto

/*
declare global {
    interface SubtleCrypto {
        importKey(
            format: string,
            keyData: ArrayBuffer,
            algorithm: { name: string },
            extractable: boolean,
            keyUsages: string[]
        ): PromiseLike<CryptoKey>;
    }
}
*/

async function webcryptoDeriveKey(subtle: SubtleCrypto, pin: string): Promise<CryptoKey> {
    const textEncoder = new TextEncoder();
    const material = await subtle.importKey('raw', textEncoder.encode(pin), 'PBKDF2', false, ['deriveKey']);
    const key = await subtle.deriveKey(
        {
            'name': 'PBKDF2',
            'salt': textEncoder.encode('Practique'),
            'iterations': 1009,
            'hash': 'SHA-256'
        },
        material,
        {
            'name': 'AES-GCM',
            'length': 256
        },
        true,
        ['decrypt']
    );
    return key;
}

async function webcryptoImportKey(subtle: SubtleCrypto, key: ArrayBuffer): Promise<CryptoKey> {
    return subtle.importKey('raw', key, {name: 'AES-GCM'}, false, ['decrypt']) as Promise<CryptoKey>;
}

async function webcryptoDecryptChunk(
    subtle: SubtleCrypto,
    aesKey: CryptoKey,
    aesIv: Uint8Array,
    ciphertext: Uint8Array
): Promise<ArrayBuffer> {
    const plaintext = await subtle.decrypt({ 'name': 'AES-GCM', 'iv': aesIv }, aesKey, ciphertext);
    return plaintext;
}

// AsmCrypto

function asmcryptoDeriveKey(pin: string): ArrayBuffer {
    const textEncoder = new TextEncoder();
    return Pbkdf2HmacSha256(textEncoder.encode(pin), textEncoder.encode('Practique'), 1009, 32).buffer;
}

function asmcryptoDecryptChunk(
    aesKey: ArrayBuffer,
    aesIv: Uint8Array,
    ciphertext: Uint8Array
): ArrayBuffer {
    return AES_GCM.decrypt(ciphertext, new Uint8Array(aesKey), aesIv, undefined, 16).buffer;
}

export async function pinToKey(pin: string): Promise<ArrayBuffer> {
    const subtle = crypto.subtle || crypto.webkitSubtle;
    if (subtle) {
        try {
            const key = await webcryptoDeriveKey(subtle, pin);
            return await subtle.exportKey('raw', key);
        } catch(err) {
            console.log('pinToKey: fallback to asmcrypto');
        }
    }
    return asmcryptoDeriveKey(pin);
}

declare global {
    interface Crypto {
        webkitSubtle: typeof crypto.subtle;
    }
}

class Decryptor {
    private webcryptoAesKey?: CryptoKey;
    private asmcryptoAesKey?: ArrayBuffer;
    private subtle?: SubtleCrypto;
    private pin: string;

    constructor(pin: string) {
        this.subtle = crypto.subtle || crypto.webkitSubtle;
        this.pin = pin;
    }

    async decryptChunk(buf: ArrayBuffer): Promise<ArrayBuffer> {
        const aesIv = new Uint8Array(buf.slice(0, 12))
            , ciphertext = new Uint8Array(buf.slice(12, buf.byteLength))
            ;

        if (this.subtle && this.webcryptoAesKey) {
            return await webcryptoDecryptChunk(this.subtle, this.webcryptoAesKey, aesIv, ciphertext);
        } else if (this.asmcryptoAesKey) {
            return asmcryptoDecryptChunk(this.asmcryptoAesKey, aesIv, ciphertext);
        } else if (this.subtle) {
            try {
                console.log('TRY WEBCRYPTO DERIVE KEY');
                const webcryptoAesKey = await webcryptoDeriveKey(this.subtle, this.pin);
                console.log('TRY WEBCRYPTO DECRYPT');
                const data = await webcryptoDecryptChunk(this.subtle, webcryptoAesKey, aesIv, ciphertext);
                this.webcryptoAesKey = webcryptoAesKey;
                return data;
            } catch (e1) {
                console.log(e1, '\nASMCRYPTO KEY IMPORT')
                const asmcryptoAesKey = asmcryptoDeriveKey(this.pin);
                try {
                    console.log('TRY WEBCRYPTO DECRYPT');
                    const webcryptoAesKey = await webcryptoImportKey(this.subtle, asmcryptoAesKey);
                    const data = await webcryptoDecryptChunk(this.subtle, webcryptoAesKey, aesIv, ciphertext);
                    this.webcryptoAesKey = webcryptoAesKey;
                    return data;
                } catch (e2) {
                    console.log(e2, '\nASMCRYPTO  DECRYPT');
                    const data = asmcryptoDecryptChunk(asmcryptoAesKey, aesIv, ciphertext);
                    this.asmcryptoAesKey = asmcryptoAesKey;
                    return data;
                }
            }
        } else {
            console.log('ASMCRYPTO');
            const asmcryptoAesKey = asmcryptoDeriveKey(this.pin);
            const data = asmcryptoDecryptChunk(asmcryptoAesKey, aesIv, ciphertext);
            this.asmcryptoAesKey = asmcryptoAesKey;
            return data;
        }                    
    }
}

export type ExamId = string;

export class IndexedDBReader extends zip.Reader {
    private decryptor: Decryptor;
    private cacheId: number | null;
    private cacheValue: ArrayBuffer | null;
    private store: string;
    private examId: ExamId;
    private chunkSize: number;
    public readonly size: number; // zip.js needs to read this field

    public constructor(
        store: string,
        key: string,
        examId: ExamId,
        chunkSize: number,
        size: number
    ) {
        super();
        this.decryptor = new Decryptor(key);
        this.store = store;
        this.examId = examId;
        this.chunkSize = chunkSize;
        this.size = size;
        this.cacheId = null;
        this.cacheValue = null;
        console.log('CONSTRUCT: IndexedDBReader', store, key, examId, chunkSize, size);
    }

    public init(callback: () => void): void {
        console.log('init: IndexedDBReader');
        this.cacheId = null;
        this.cacheValue = null;
        callback();
    }

    private async getChunk(chunkId: number): Promise<ArrayBuffer> {
        if (chunkId == this.cacheId && this.cacheValue != null) {
            return this.cacheValue;
        } else {
            try {
                this.cacheId = null;
                this.cacheValue = null;
                const ciphertext = await dbGet<ArrayBuffer>(this.store, [this.examId, chunkId]);
                if (!ciphertext) {
                    throw 'IndexedDBReader: getChunk ' + chunkId + ' null';
                }
                const plaintext = await this.decryptor.decryptChunk(ciphertext);
                this.cacheId = chunkId;
                this.cacheValue = plaintext;
                //console.log('GOT CHUNK');
                return plaintext;
            } catch (err) {
                console.log('error: get_chunk', err.message ?? err);
                throw err;
            }
        }
    }

    public async readUint8Array(
        start: number,
        length: number,
        callback: (buf: Uint8Array) => void,
        onerror: (err: string) => void
    ): Promise<void> {
        //log(start, length, this.chunkSize);
        const startChunk = Math.floor(start / this.chunkSize);
        const startOffset = start - (startChunk * this.chunkSize);
        const end = start + length;
        const endChunk = Math.floor(end / this.chunkSize);
        const endOffset = end - (endChunk * this.chunkSize);

        //log('IndexedDBReader readUint8Array: start chunk:', startChunk, 'offset:', startOffset, ' ->  end chunk:', endChunk, 'offset:', endOffset);
        if (startChunk === endChunk) {
            // optiminse single chunk
            try {
                const buf = (await this.getChunk(startChunk)).slice(startOffset, endOffset);
                callback(new Uint8Array(buf));
            } catch (err) {
                onerror('IndexedDBReader: ' + err);
            }
        } else if (startChunk + 1 === endChunk) {
            // optimise spanning two chunks
            try {
                const buf = new Uint8Array(length);
                const buf1 = await this.getChunk(startChunk);
                buf.set(new Uint8Array(buf1).subarray(startOffset));
                const buf2 = await this.getChunk(endChunk);
                buf.set(new Uint8Array(buf2).subarray(0, endOffset), this.chunkSize - startOffset);
                callback(buf);
            } catch (err) {
                onerror(err);
            }
        } else {
            try {
                const buf = new Uint8Array(length);
                const buf1 = await this.getChunk(startChunk);
                buf.set(new Uint8Array(buf1).subarray(startOffset));
                let offset = this.chunkSize - startOffset;
                for (let chunk = startChunk + 1; chunk < endChunk; ++chunk) {
                    const buf2 = await this.getChunk(chunk);
                    buf.set(new Uint8Array(buf2), offset);
                    offset += this.chunkSize;
                }
                const buf3 = await this.getChunk(endChunk);
                buf.set(new Uint8Array(buf3).subarray(0, endOffset), offset);
                callback(buf);
            } catch (err) {
                onerror(err);
            }
        }
    }

    public close(): void {
        this.cacheId = null;
        this.cacheValue = null;
    }
}
