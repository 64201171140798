import { Canvas, createCanvas, createImageData, CanvasRenderingContext2D } from 'canvas';
import { Transform } from '../utils';
import { Img, IType, Renderer, registerRendererType } from '../image-base';

declare interface Tile {
    items: Int16Array | Uint16Array | Int8Array | Uint8Array;
}

declare class JpxImage {
    public parse(data: Uint8Array): void;
    public width: number;
    public height: number;
    public componentsCount: number;
    public tiles: Tile[];
}

export interface J2k extends Img {}

export type Type = J2k;

function jpeg2kToCanvas(buffer: ArrayBuffer): Canvas {
    const decoder = new JpxImage();
    decoder.parse(new Uint8Array(buffer));
    const decoded = decoder.tiles[0].items;
    const canvas = createCanvas(decoder.width, decoder.height);
    const context = canvas.getContext('2d', {alpha: false});
    const image16 = new Uint16Array(decoded.buffer);
    const pixels = Math.floor(image16.length / 3);
    const image8 = new Uint8ClampedArray(4 * pixels);
    let src = 0;
    let dst = 0;
    while (dst < image8.length) {
        image8[dst++] = image16[src++]; // / 256.0;
        image8[dst++] = image16[src++]; // / 256.0;
        image8[dst++] = image16[src++]; // / 256.0;
        image8[dst++] = 255;
    }
    console.log('BUFFER', image8.byteLength, canvas.width, canvas.height);
    context.putImageData(createImageData(image8, canvas.width, canvas.height), 0, 0);
    return canvas;
}

function jpeg2kThumbnail(buffer: ArrayBuffer|null, {width, height}: {width?: number, height?: number}): ImageData {
    if (buffer === null) {
        throw TypeError('ArrayBuffer is null');
    }
    const image = jpeg2kToCanvas(buffer);
    if (width === undefined) {
        if (height === undefined) {
            width = image.width;
            height = image.height;
        } else {
            width = Math.floor(height * image.width / image.height);
        }
    } else if (height === undefined) {
        height = Math.floor(width * image.height / image.width);
    }
    const canvas = createCanvas(width, height);
    const context = canvas.getContext('2d', { alpha: false });
    context.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
    return context.getImageData(0, 0, width, height);
}

export function makeJ2k(id: string, data: ArrayBuffer[]): J2k {
    return {
        id,
        iType: IType.J2k,
        data,
        dataSize: data.map((buf: ArrayBuffer): number => buf.byteLength),
        frames: 1,
    }
}

export function isJ2k(x: Img): x is J2k {
    return x.iType === IType.J2k;
}

export function j2kDetect(buffer: ArrayBuffer): boolean {
    if (buffer.byteLength < 8) {
        return false;
    }
    const data = new Uint8Array(buffer);
    console.log('IS_J2K: ',
        data[0].toString(16),
        data[1].toString(16),
        data[2].toString(16),
        data[3].toString(16),
        data[4].toString(16),
        data[5].toString(16),
        data[6].toString(16),
        data[7].toString(16)
    );
    return data[0] === 0x00 && data[1] === 0x00 && data[2] === 0x00 && data[3] === 0x0C &&
        data[4] === 0x6A && data[5] === 0x50 && data[6] === 0x20 && data[7] === 0x20;
}

export class J2kRenderer implements Renderer {
    public index: number;
    public readonly img: J2k;
    private canvas?: Canvas;

    public constructor(img: J2k) {
        this.img = img;
        this.index = 0;
    }

    public destroy(): void {
        return
    }

    public async render(): Promise<void> {
        return;
    }

    public async renderThumbnail(): Promise<ImageData> {
        return jpeg2kThumbnail(this.img.data[0], {height: 120})
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        context.fillStyle = 'black';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        if (this.canvas) {
            context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            context.drawImage(this.canvas, 0, 0);
            //    Math.floor(context.canvas.width / 2 - this.canvas.width / 2),
            //    Math.floor(context.canvas.height / 2 - this.canvas.height / 2)
            //);  
        }
    }

    public async load(
        begin: () => Promise<void>,
        frame: (image: Img, frame: number) => Promise<ArrayBuffer>, 
        end: () => Promise<void>,
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        await begin();
        this.img.data.length = this.img.frames;
        let j = this.index;
        for (let i = 0; i < this.img.frames; ++i) {
            j = (j === this.img.frames) ? 0 : j;
            const f = await frame(this.img, j);
            if (j === this.index) {
                this.canvas = jpeg2kToCanvas(f);
                this.img.cols = this.canvas.width;
                this.img.rows = this.canvas.height;
                render();
            }
            progress(((i + 2) * 100.0) / (this.img.frames + 1));
            ++j;
        }
        await end();
    }

    public convexMean(): {mean?: number, stddev?: number} {
        return {};
    }
}

export function isJ2kRenderer(renderer: Renderer): renderer is J2kRenderer {
    return isJ2k(renderer.img);
}

registerRendererType({
    name: 'J2kRenderer',
    hasMime(mime: string) {
        return mime === 'image/jp2';
    },
    makeImg(id: string, buffer: ArrayBuffer) {
        return makeJ2k(id, [buffer]);
    },
    isThis(resource: Img) {
        return resource.iType === IType.J2k;
    },
    makeRenderer(resource: J2k): Renderer {
        return new J2kRenderer(resource);
    }
});
