import { crc32, UInt64, PCG32, shuffle, mkNode, safeJsonParse, deref, getFreeSpace, wait, isIndexed } from './utils';
import { dbGet, dbPut, dbCursor, dbDelete, IndexedDBReader, IDBReader, IDBWriter, ExamId, StorageError } from './utils-db';
import { HttpError, postJson, getHead, getText, getArrayBuffer, urlWithCredentials,/*, examsApi*/ 
rangeSupported, getJson, requestJson} from './utils-net';
import { Img, IType } from './image-base';
import { makeStd, StdRenderer } from './Renderers/render-std';
import { makeTiff, tiffDetect, TiffRenderer } from './Renderers/render-tiff';
import { makePdf, PdfRenderer } from './Renderers/render-pdf';
import { makeDicom, dicomCompatible, DicomRenderer, DicomImg, sortKeys } from './Renderers/render-dicom';
import { QuestionManifest, AnswerResources, AnswerValue, AnswerKey } from './question-base';
import { Progress } from './utils-progress';
//import { ExamAdmin, ExamItem, ExamAdminOrList } from './p4b-api';
import examValidate from 'validate_exam';
import 'zip-js';
import { j2kDetect, J2kRenderer, makeJ2k } from 'Renderers/render-jpeg2k';
import { translate } from 'utils-lang';
import { isSchedule } from 'exam-timer';
import { ExamState, isState } from 'exam-viewer';

zip.useWebWorkers = false;

export type ExamMap = Map<string, ExamItem>;
/*
interface ExamMeta {
    answer_aes_key?: string;
    randomize?: boolean;
    demo?: boolean;
    show_question_title?: boolean;
    enableCopyPaste?: boolean;
    disableResourceLocking?: boolean;
}
*/

export interface RemoteExamItem {
    id: string;
    component: number;
    version: number;
    title: string;
    full_title: string;
    link: string;
    proctored?: boolean;
    demo?: boolean;
    pin?: string;
    state?: string;
}

export interface ExamItem extends RemoteExamItem {
    size: number;
}

export interface Structure {
    backendQid: number[];
    backendAid: number[];
    //backendFid?: string;
    answerType: string[];
    indents?: number[];
    displayNumber: (string|undefined)[];
    //shortNumber: string[];
    //fullNumber: string[];
    length: number;
    visible: (Expr | undefined)[];

    question: number;
    factor?: string;
    interview?: number;
    round?: number;
    circuit?: number;
    room?: number;
}

export interface State {
    // Exam
    //thisExam: PractiqueNet.ExamJson.Definitions.ExamMeta;
    exams: ExamMap;
    structure: Structure[]; // (HTMLElement[] | Structure)[];
    //order: number[];

    // Candidate
    examCid?: string;
    candidateCid?: string;
    candidatePin?: string;
    admin?: boolean;

    // Factors
    factorDetails?: { [ix: string]: PractiqueNet.ExamJson.Definitions.UserDetails };

    // Question
    qid?: number;
    aid?: number;

    // Misc
    //webcryptoAesKey?: CryptoKey;
    //asmcryptoAesKey?: ArrayBuffer;
    badPin?: boolean;
    badCid?: boolean;
    examPin?: string;
    chosenExamId?: ExamId;
    downloaded?: boolean;
}

//----------------------------------------------------------------------------
// Assigment Data Model

export interface ExamAdmin {
    admin?: boolean;
    exams: RemoteExamItem[];
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamAdmin(x: any): x is ExamAdmin {
    return (x as ExamAdmin).exams != undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamIndex(x: any): x is RemoteExamItem[] {
    return (x as ExamItem[]).length != undefined
}

export async function getExamList(): Promise<{admin: boolean; exams?: RemoteExamItem[]}> {
    const response = await getJson(urlWithCredentials('/app/available_exams.json'));
    if (isExamAdmin(response)) {
        return {admin: response.admin === true, exams: response.exams};
    } else if (isExamIndex(response)) {
        return {admin: false, exams: response};
    } else {
        return {admin: false};
    }
}

export interface ProctorExamity {
    redirectUrl: string;
    UserName: string;
}

export interface Proctors {
    [key:string]: unknown;
    examity?: ProctorExamity;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isProctors(x: any): x is Proctors {
    return x && typeof x === 'object' && (
        typeof x.examity === 'undefined' || (
            x.examity && typeof x.examity === 'object' &&
            typeof x.examity.redirectUrl === 'string' &&
            typeof x.examity.UserName === 'string'
        )
    )
}

export async function getProctors(): Promise<Proctors> {
    try {
        const proctors = await getJson(urlWithCredentials('/app/proctors'));
        if (!isProctors(proctors)) {
            throw TypeError('Validation of server proctors object failed.');
        }
        return proctors;
    } catch (err) {
        if (err instanceof Error) {
            console.error(err.message);
        }
        return {};
    }
}

export function proctorExamity(config: ProctorExamity): void {
    const {redirectUrl, UserName} = config;
    console.debug('EXAMITY', redirectUrl, UserName);
    const form = mkNode('form', {attrib: {method: 'post', action: redirectUrl}});
    mkNode('input', {parent: form, attrib: {type: 'hidden', name: 'UserName', value: UserName}});
    document.body.appendChild(form);
    form.submit();
}

export async function proctoringInit(): Promise<void> {
    const proctors = await getProctors();
    if (proctors.examity) {
        proctorExamity(proctors.examity);
    }
}

//----------------------------------------------------------------------------
// Encrypted Exam Data Model

function isExam(x: unknown): x is PractiqueNet.ExamJson {
    return examValidate(x);
}

function isImageSet(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.ImageSet {
    return (x as PractiqueNet.ExamJson.Definitions.ImageSet).set != undefined;
}

function isImageSingle(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.ImageSingle {
    return (x as PractiqueNet.ExamJson.Definitions.ImageSingle).image != undefined;
}

function isResource(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.Resource {
    return (x as PractiqueNet.ExamJson.Definitions.Resource).type != undefined;
}

export async function deleteExam(exams: ExamMap, examId: ExamId | undefined, notifyServer = false): Promise<void> {
    if (examId == null) {
        throw 'invalid exam id';
    }
    await dbDelete('exams', examId);
    await dbDelete('encrypted', IDBKeyRange.bound(
        [examId, 0],
        [examId, Number.MAX_SAFE_INTEGER]
    ));
    if (notifyServer) {
        try {
            await getText(urlWithCredentials('/app/' + exams.get(examId)?.id + '/deleted/'));
        } catch (err) {
            console.error('DELETE_EXAM', err.message ?? err);
        }
    }
    exams.delete(examId);
}

export async function updateLocalExams(remoteExams: RemoteExamItem[]): Promise<void> {
    for (const remoteExam of remoteExams) {
        const key = [remoteExam.id, remoteExam.component].join('-');
        const localExam = await dbGet<ExamItem>('exams', key);
        if (localExam) {
            const updatedExam = {...remoteExam, size: localExam.size};
            await dbPut('exams', key, updatedExam);
        }
    }
}

export async function getLocalExams(): Promise<ExamMap> {
    const exams = new Map<ExamId, ExamItem>();
    await dbCursor('exams', undefined, (cursor): void => {
        exams.set(cursor.key as ExamId, cursor.value);
    });
    return exams;
}

export async function deleteUnavailable(localExams: ExamMap, remoteExams: RemoteExamItem[]): Promise<void> {
    const deleteSet = new Set(localExams.keys());
    for (const exam of remoteExams) {
        deleteSet.delete([exam.id, exam.component].join('-'));
    }
    for (const examId of Array.from(deleteSet.values())) {
        await deleteExam(localExams, examId);
        localExams.delete(examId);
    }
}

export function getFetchList(localExams: ExamMap, remoteExams: RemoteExamItem[]): RemoteExamItem[] {
    console.debug('LOCAL', Array.from(localExams.values()));
    console.debug('REMOTE', remoteExams);
    const remoteMap = new Map(remoteExams.map(ex => [[ex.id, ex.component].join('-'), ex]));
    for (const examId of Array.from(localExams.keys())) {
        remoteMap.delete(examId);
    }
    console.debug('FETCH', Array.from(remoteMap.values()));
    return Array.from(remoteMap.values());
}

async function getExamSizes(fetchList: RemoteExamItem[]): Promise<number[]> {
    const sizes: number[] = [];
    for (const exam of fetchList) {
        let size = 0;
        try {
            size = parseInt(await getHead(urlWithCredentials(exam.link)));
        } catch (err) {
            if (err instanceof HttpError && err.status === 412) {
                alert(`Download count exceeded: ${exam.title}`);
                console.warn('DOWNLOAD_COUNT_EXCEEDED', exam.title);
            } else {
                alert(String(err));
                console.warn('GET_EXAM_SIZES', String(err));
            }
        }
        sizes.push(size);
    }
    return sizes;
}

/*
async function updateExamsFromRemote(localExams: ExamMap, remoteExams: RemoteExamItem[]): Promise<void> {
    const localSet = new Set(localExams.keys());
    for (const exam of remoteExams) {
        localSet.delete([exam.id, exam.component].join('-'));
    }
    for (const examId of Array.from(localSet.values())) {
        await deleteExam(localExams, examId);
    }
    
    for (const remoteExam of remoteExams) {
        const eid = [remoteExam.id, remoteExam.component].join('-');
        let localExam = localExams.get(eid);
        if (localExam) {
            if (localExam.version !== remoteExam.version) {
                try {
                    localExam = Object.assign(localExam, remoteExam);
                    localExam.local = false;
                    localExam.remote = true;
                    const url = urlWithCredentials(localExam.link);
                    const sizeStr = await getHead(url);
                    localExam.size = parseInt(sizeStr);
                    await dbPut('exams', eid, localExam);
                    localExams.set(eid, localExam);
                } catch (err) {
                    console.error(err);
                }
            } else {
                localExam = Object.assign(localExam, remoteExam);
                await dbPut('exams', eid, localExam);
                localExams.set(eid, localExam);
            }
        } else {
            try {
                const url = urlWithCredentials(remoteExam.link);
                const sizeStr = await getHead(url);
                const size = parseInt(sizeStr);
                localExam = Object.assign(remoteExam, {remote: true, local: false, size: size});
                await dbPut('exams', eid, localExam);
                localExams.set(eid, localExam);
            } catch (err) {
                console.error(err);
            }
        }
    }
        //}
    //});
}
*/

async function storeExamChunks(examId: ExamId, pos: number, chunks: ArrayBuffer): Promise<void> {
    const size = chunks.byteLength;
    const chunkSize = 0x100001c;
    const count = Math.ceil(size / chunkSize);
    let start = 0;
    for (let i = pos; i < pos + count; ++i) {
        const end = (size - start > chunkSize) ? (start + chunkSize) : size;
        console.debug('PUT ENCRYPTED', [examId, i]);
        await dbPut('encrypted', [examId, i] as IDBArrayKey, chunks.slice(start, end));
        console.debug ('ENCRYPTED DONE');
        start = end;
    }
}

async function fetchExam(id: ExamId, url: string, _size: number, progress: Progress): Promise<void> {
    const {ranged, size} = await rangeSupported(urlWithCredentials(url));
    console.debug('RANGED', ranged, size);
   
    if (ranged) {
        const chunkSize = 0x100001c;
        const chunks = Math.ceil(size / chunkSize);
        const lastChunk = (size === chunks * chunkSize) ? chunkSize : (size - chunks * chunkSize);

        for (let n = 0; n < chunks; ++n) {
            const buf = await dbGet<ArrayBuffer>('encrypted', [id, n]);
            if (!buf || ((n === chunks - 1) ? (buf.byteLength !== lastChunk) : (buf.byteLength !== chunkSize))) {
                // missing or incomplete chunk
                const start = chunkSize * n;
                const end = (start + chunkSize > size) ? size : start + chunkSize;
                console.debug('RANGED', start, end);
                const buf2 = await getArrayBuffer(url, progress, start, end - 1);
                await dbPut('encrypted', [id, n] as IDBArrayKey, buf2);
            } else {
                console.debug('CHECKED', n);
                await progress.setProgress(chunkSize * (n + 1));
            }
        }
    } else {
        console.debug('UNRANGED');
        const data = await getArrayBuffer(url, progress);
        await storeExamChunks(id, 0, data);
    }
}

export async function fetchExams(fetchList: RemoteExamItem[], progress: Progress): Promise<boolean> {
    const sizes = await getExamSizes(fetchList);
    const downloadCount = fetchList.length;
    const totalSize = sizes.reduce((a, b) => a + b, 0);

    if (totalSize === 0) {
        return false;
    }

    const freeSpace = (await getFreeSpace())?.toLocaleString();
    progress.setTotalSize(totalSize);
    progress.setTitle(translate('DOWNLOAD_TITLE', {downloadCount}));
    progress.setDescription(translate('DOWNLOAD_DESCRIPTION', {freeSpace}));
    progress.onProgress = async () => {
        const freeSpace = (await getFreeSpace())?.toLocaleString();
        progress.setDescription(translate('DOWNLOAD_DESCRIPTION', {freeSpace}));
        //await wait(5000);
    }

    console.debug('GOT SIZES', sizes);

    let failCount = 0;
    for (let i = 0; i < fetchList.length; ++i) {
        if (sizes[i] > 0) {
            const exam = fetchList[i];
            const key = `${exam.id}-${exam.component}`;
            try {
                await fetchExam(key, urlWithCredentials(exam.link), sizes[i], progress);
                await dbPut('exams', key, {...exam, size: sizes[i]});
                await getText(urlWithCredentials(`/app/${exam.id}/downloaded/`));
            } catch(err) {
                if (err instanceof HttpError && err.status == 412) {
                    alert(`Download count exceeded: ${exam.title}`);
                } else if (err instanceof StorageError) {
                    //await dbDelete('encrypted', IDBKeyRange.bound(
                    //    [key, 0],
                    //    [key, Number.MAX_SAFE_INTEGER]
                    //));
                    throw err;
                } else {
                    console.warn(String(err));
                }
                ++failCount;
            }
        }
    }
    return fetchList.length > failCount;
}

//----------------------------------------------------------------------------
// Prepare Exam

class ArrayBufferWriter extends zip.Writer {
    private u8: Uint8Array;
    private p: number;
    private length: number;

    public constructor(length: number) {
        super();
        this.length = length;
        this.u8 = new Uint8Array(this.length);
        this.p = 0;
    }

    public init(callback: () => void): void {
        this.p = 0;
        callback();
    }

    public writeUint8Array(array: Uint8Array, callback: () => void): void {
        this.u8.set(array, this.p);
        this.p += array.length;
        callback();
    }

    public getData(callback: (buf: ArrayBuffer) => void): void {
        callback(this.u8.buffer);
    }

}

function zipGetEntries(reader: zip.Reader): Promise<zip.Entry[]> {
    console.debug('ZIP_GET_ENTRIES');
    return new Promise((succ, fail): void => {
        zip.createReader(reader, (reader): void => {
            console.debug('zip.createReader');
            reader.getEntries(succ);
        }, (err): void => {
            console.error('error: zip_create_reader', err);
            fail('probably bad pin');
        });
    });
}

function zipExtractText(entry: zip.Entry): Promise<string> {
    return new Promise<string>((succ): void => {
        entry.getData(new zip.TextWriter('text/plain'), succ);
    });
}

function zipExtractArraybuffer(entry: zip.Entry): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((succ): void => {
        entry.getData(new ArrayBufferWriter(entry.uncompressedSize), succ);
    });
}

function zipExtract(entry: zip.Entry, writer: zip.Writer): Promise<void> {
    return new Promise<void>((succ): void => {
        entry.getData(writer, succ);
    });
}

function countImages(images: PractiqueNet.ExamJson.Definitions.Image[]) {
    let x = 0;
    for (const img of images) {
        if (isImageSet(img)) {
            x += img.set.length;
        } else {
            ++x;
        }
    }
    return x;
}

function countQuestionsImages(used: Set<number>, questions: PractiqueNet.ExamJson.Definitions.Question[]) {
    let x = 0;
    for (const question of questions) {
        if (used.has(question.backend_id)) {
            //console.log('COUNT', question.backend_id);
            x += countImages(question.images);
            if (question.answersResources) {
                for (const answerResources of question.answersResources) {
                    x += countImages(answerResources);
                }
            }
        }
    }
    return x;
}

function countExamImages(used: Set<number>, exam: PractiqueNet.ExamJson): number {
    let x = countQuestionsImages(used, exam.questions);
    if (exam.variantQuestions) {
        for (const variant of exam.variantQuestions) {
            x += countQuestionsImages(used, variant);
        }
    }
    return x;
}

export interface Storage {
    unpackExam(key: string, examId: ExamId, examCid: string, size: number, progress: Progress): Promise<{manifest: PractiqueNet.ExamJson, structure: Structure[]}>;
    getImageBegin(): Promise<void>;
    getImageFrame(image: Img, frame: number): Promise<Uint8Array>;
    getImageEnd(): Promise<void>;
}

function getStructure(manifest: PractiqueNet.ExamJson, examCid: string): {structure: Structure[], used: Set<number>} {
    let sections: PractiqueNet.ExamJson.Definitions.Section[] | undefined = undefined;
    if (manifest.defaultSections) {
        sections = manifest.defaultSections;
    }
    if (manifest.candidateSections) { // if sections provided lookup this CID.
        sections = manifest.candidateSections[examCid];
    }
    const questionMap = new Map<number, number>();
    if (sections == undefined) {
        const order = [];
        for (let i = 0; i < manifest.questions.length; ++i) {
            const question = manifest.questions[i];
            order.push({ question: question.backend_id });
            questionMap.set(question.backend_id, i);
        }
        sections = [{
            items: order
        }];
    } else {
        for (let i = 0; i < manifest.questions.length; ++i) {
            questionMap.set(manifest.questions[i].backend_id, i);
        }
    }
    console.debug('ORIGINAL SECTIONS:', sections);
    const examHash = crc32(manifest.exam.answer_aes_key);
    const candHash = crc32(examCid);
    const random = new PCG32(new UInt64(candHash, examHash), new UInt64(examHash, candHash));
    for (const section of sections) {
        if (section.randomize || manifest.exam.randomize) {
            shuffle(random, section.items);
        }
    }
    console.debug('FINAL SECTIONS:', sections);
    const used = new Set<number>();
    //const order = [];
    let index = 0;
    const structure = [] as Structure[];
    for (const section of sections) {
        for (const item of section.items) {
            if (item.question != undefined) {
                used.add(item.question);
                //order.push(item.question);
                const i = questionMap.get(item.question);
                console.debug('MAP:', item.question, i);
                if (i != undefined) {
                    const question = manifest.questions[i];
                    /*let lastNum: string|undefined;
                    let count = 1;
                    const displayNum: string[] = [(index + 1).toString()];
                    const displayFull: string[] = [];
                    const displayShort: string[] = [];
                    if (question.answers.length === 1) {
                        displayShort.push('');
                        displayFull.push((index + 1).toString());
                    } else {
                        let indent = 0;
                        for (const answer of question.answers) {
                            const answerIndent = answer.indent ?? 0;
                            if (indent <= (answerIndent || 0) + 1) {
                                while (indent < (answerIndent || 0) + 1) {
                                    if (lastNum) {
                                        displayNum.push(lastNum);
                                    }
                                    ++indent;
                                }
                            } else if (indent > (answerIndent || 0) + 1) {
                                while (indent > (answerIndent || 0) + 1) {
                                    displayNum.pop();
                                    --indent;
                                }
                            }
                            lastNum = answer.displayNumber ?? '';
                            
                            if (answer.type.toLowerCase() !== 'label') {
                                if (answer.displayNumber) {
                                    displayFull.push(displayNum.join(' ') + ' ' + lastNum);
                                    displayShort.push(answer.displayNumber);
                                } else {
                                    displayFull.push((count).toString());
                                    displayShort.push((count).toString());
                                    count++;
                                }
                            } else {
                                displayFull.push('');
                                if (answer.displayNumber) {
                                    displayShort.push(answer.displayNumber);
                                } else {
                                    displayShort.push('');
                                }
                            }
                        }
                    }*/
                    const variantIds = [question.backend_id];
                    if (manifest.variantQuestions) {
                        const x = manifest.variantQuestions[i];
                        for (let j = 0; j < x.length; ++j) {
                            variantIds.push(x[j].backend_id);
                            used.add(x[j].backend_id);
                        }
                    }
                    console.debug('VARIANT_IDS', variantIds);
                    structure.push({
                        backendQid: variantIds, // question.backend_id,
                        backendAid: question.answers.map((a): number => a.backend_id),
                        answerType: question.answers.map((a): string => a.type),
                        indents: question.answers.map((a): number => a.indent ?? 0),
                        displayNumber: question.answers.map((a): string|undefined => a.displayNumber),
                        //shortNumber: displayShort, //question.answers.map((a): string => a.displayNumber ?? ''),
                        //fullNumber: displayFull, //question.answers.map((_, j): string => displayFull[j]),
                        //backendFid: item.factor,
                        length: question.answers.length,
                        visible: question.answers.map((a): Expr | undefined => a.visible),
                        question: index++,
                        factor: item.factor,
                        interview: item.interviewId,
                        round: item.round,
                        circuit: item.circuit,
                        room: item.room,
                    });
                }
            } else {
                if (!manifest.exam.hideEmptyItems) {
                    structure.push({
                        backendQid: [],
                        backendAid: [],
                        answerType: [],
                        indents: [],
                        displayNumber: [],
                        length: 0,
                        visible: [],
                        question: index++,
                        round: item.round,
                        circuit: item.circuit,
                        room: item.room,
                    });
                }
            }
        }
    }
    console.debug('STRUCTURE DONE');
    return {structure, used};
}

export class StoreChunks implements Storage {
    private readChunkSize: number;
    private writeChunkSize: number;
    private imageReader: IDBReader | null;

    public constructor(readChunkSize: number, writeChunkSize: number) {
        this.readChunkSize = readChunkSize;
        this.writeChunkSize = writeChunkSize;
        this.imageReader = null;
    }
    
    public async unpackExam(key: string, examId: ExamId, examCid: string, size: number, progress: Progress):
        Promise<{manifest: PractiqueNet.ExamJson, structure:Structure[]}>
    {
        console.debug('BEGIN UNZIP');
        const t0 = performance.now();

        const freeSpace = (await getFreeSpace())?.toLocaleString();
        progress.setTitle(translate('PREPARING_TITLE'));
        progress.setDescription(translate('PREPARING_DESCRIPTION', {freeSpace}));
        progress.onProgress = async () => {
            const freeSpace = (await getFreeSpace())?.toLocaleString();
            progress.setDescription(translate('PREPARING_DESCRIPTION', {freeSpace}));
            //await wait(5000);
        }

        const entries = await zipGetEntries(new IndexedDBReader('encrypted', key, examId, this.readChunkSize, size));
        console.debug('ENTRIES', entries);
        const files = entries.reduce((map, obj): Map<string, zip.Entry> => {
            map.set(obj.filename, obj);
            return map;
        }, new Map<string, zip.Entry>());

        const writer = new IDBWriter('images', this.writeChunkSize);
        const prepare = new PrepareExam(writer, files, progress);
        const manifest = await prepare.unpackManifest();

        if (manifest.candidates.indexOf(examCid) < 0) {
            throw translate('ERROR_CANDIDATE_NOT_FOUND');
        }

        const {structure, used} = getStructure(manifest, examCid);

        progress.setTotalSize(1 + countExamImages(used, manifest));
        await progress.setProgress(1);

        await prepare.unpackQuestions(used, manifest.questions);
        manifest.questions.splice(0, manifest.questions.length);
        if (manifest.variantQuestions) {
            for (const variants of manifest.variantQuestions) {
                await prepare.unpackQuestions(used, variants);
                variants.splice(0, variants.length);
            }
            manifest.variantQuestions.splice(0, manifest.variantQuestions.length);
        }
        await writer.close();

        console.debug('TIMING', manifest.exam.timing);

        const t1 = performance.now();
        console.info('UNPACKING TIME: ', (t1 - t0), 'ms');
        return {manifest, structure};
    }

    public async getImageBegin(): Promise<void> {
        this.imageReader = new IDBReader('images', this.writeChunkSize);
    }

    public async getImageFrame(image: Img, frame: number): Promise<Uint8Array> {
        if (image.dbOffset == null) {
            throw 'db_offset is null';
        }
        if (image.dataSize.length <= frame) {
            throw 'image frame past end';
        }
        let start = image.dbOffset;
        for (let i = 0; i < frame; ++i) {
            start += image.dataSize[i];
        }
        const end = start + image.dataSize[frame];
        if (this.imageReader == null) {
            throw 'image_reader is null';
        }
        console.debug('GET_IMAGE_FRAME', start, end);
        return await this.imageReader.readUint8Array(start, end);
    }

    /*
    public getImageBlob(image: Img, frame: number): Promise<Blob> {
        if (image.dbOffset == null) {
            return Promise.reject('db_offset is null');
        }
        let start = image.dbOffset;
        for (let i = 0; i < frame; ++i) {
            start += image.dataSize[i];
        }
        const end = start + image.dataSize[frame];
        if (this.imageReader == null) {
            return Promise.reject('image_reader is null');
        }
        return this.imageReader.readBlob(start, end);
    }
    */

    public async getImageEnd(): Promise<void> {
        if (this.imageReader != null) {
            this.imageReader.close();
            this.imageReader = null;
        }
    }
}


class PrepareExam {
    private writer: IDBWriter;
    private files: Map<string, zip.Entry>;
    private progress: Progress;
    private count = 1;
    private offset: Map<string, number> = new Map();

    constructor(writer: IDBWriter, files: Map<string,zip.Entry>, progress: Progress) {
        this.writer = writer;
        this.files = files;
        this.progress = progress;
    }

    private async writeFrames(data: (ArrayBuffer|null)[]): Promise<void> {
        while (data.length > 0) {
            const frame = data.shift();
            if (frame) {
                await this.writer.writeUint8ArrayPromise(new Uint8Array(frame));
            }
            await this.progress.setProgress(++this.count);
        }
    }

    public async unpackDicomSet(imageSet: PractiqueNet.ExamJson.Definitions.ImageSet): Promise<{
        thumbnail?: ImageData, dicom?: Img
    }> {
        if (imageSet.set.length <= 0) {
            throw 'no images';
        }

        let thumbnail: ImageData|undefined = undefined;
        let dicom: DicomImg|undefined = undefined;
        for (let i = 0; i < imageSet.set.length; ++i) {
            const frame = imageSet.set[i];
            try {
                const entry = this.files.get(frame)
                if (entry) {
                    const data = await zipExtractArraybuffer(entry);
                    const newDicom = makeDicom(frame, data);
                    if (!dicom) {
                        dicom = newDicom;
                        dicom.caption = imageSet.caption || '';
                        dicom.distribution = imageSet.distribution;
                        dicom.dbOffset = this.writer.getPtr();
                        const renderer = new DicomRenderer(dicom);
                        thumbnail = await renderer.renderThumbnail();
                        await this.writeFrames(dicom.data);
                    } else if (dicomCompatible(dicom, newDicom)) {
                        await this.writeFrames(newDicom.data);
                        dicom.dataSize = dicom.dataSize.concat(newDicom.dataSize);
                        dicom.sizes = dicom.sizes.concat(newDicom.sizes);
                        dicom.sortKey = dicom.sortKey.concat(newDicom.sortKey);
                        dicom.frames += newDicom.frames;
                    }
                }
            } catch (err) {
                if (err instanceof Error) {
                    err.message = 'resource ' + frame + ', ' + err.message;
                    throw err;
                } else {
                    throw new Error('resource ' + frame + ', ' + String(err));
                }
            }
        }

        if (dicom) {
            console.debug('START_SORT_KEYS', dicom.sortKey);
            sortKeys(dicom);
            console.debug('FINAL_SORT_KEYS', dicom.sortKey);
            dicom.sizes = deref(dicom.sizes, dicom.sortKey);
        }
        
        return {thumbnail, dicom};
    }

    public async unpackImageStd(image: PractiqueNet.ExamJson.Definitions.ImageSingle): Promise<{
        thumbnail?: ImageData, std?: Img
    }> {
        let thumbnail = undefined;
        let std = undefined;
        const entry = this.files.get(image.image);
        if (entry) {
            try {
                const data = await zipExtractArraybuffer(entry);
                const renderer = tiffDetect(data)
                    ? new TiffRenderer(makeTiff(image.image, [data]))
                    : (j2kDetect(data) 
                        ? new J2kRenderer(makeJ2k(image.image, [data]))
                        : new StdRenderer(makeStd(image.image, [data]))
                    );
                std = renderer.img;
                std.caption = image.caption || '';
                std.distribution = image.distribution;
                std.dbOffset = this.writer.getPtr();
                thumbnail = await renderer.renderThumbnail();
                await this.writeFrames(std.data);
            } catch (err) {
                throw new Error('resource ' + image.image + ', ' + err.message);
            }
        }
        return {thumbnail, std};
    }

    public async unpackAudio(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, audio?: Img
    }> {
        let audio = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const offset = this.writer.getPtr();
            await zipExtract(entry, this.writer);
            const length = this.writer.getPtr() - offset;
            audio = {
                id: resource.file,
                iType: IType.Audio,
                data: [],
                caption: resource.caption || '',
                distribution: resource.distribution,
                dataSize: [length],
                mime: resource.mime ?? undefined,
                frames: 1,
                dbOffset: offset,
            };
            await this.progress.setProgress(++this.count);
        }
        return {audio};
    }

    public async unpackVideo(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, video?: Img
    }> {
        let video = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const offset = this.writer.getPtr();
            await zipExtract(entry, this.writer);
            const length = this.writer.getPtr() - offset;
            video = {
                id: resource.file,
                iType: IType.Video,
                data: [],
                caption: resource.caption || '',
                distribution: resource.distribution,
                dataSize: [length],
                mime: resource.mime ?? undefined,
                frames: 1,
                dbOffset: offset,
            };
            await this.progress.setProgress(++this.count);
        }
        return {video};
    }

    public async unpackPdf(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, pdf?: Img
    }> {
        let thumbnail = undefined;
        let pdf = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const data = await zipExtractArraybuffer(entry);
            const renderer = new PdfRenderer(makePdf(resource.file, [data]));
            pdf = renderer.img;
            pdf.caption = resource.caption || '';
            pdf.distribution = resource.distribution;
            pdf.dbOffset = this.writer.getPtr();
            thumbnail = await renderer.renderThumbnail();
            renderer.destroy();
            await this.writeFrames(pdf.data);
        }
        return {thumbnail, pdf};
    }

    public async unpackResources(images: PractiqueNet.ExamJson.Definitions.Image[]): Promise<{
        thumbnails: (ArrayBuffer|null)[], resources: Img[] 
    }> {
        const thumbnails = [];
        const resources: Img[] = [];
        for (let j = 0; j < images.length; ++j) {
            const image = images[j];
            if (isImageSet(image)) {
                const dicomSet = await this.unpackDicomSet(image);
                if (dicomSet.dicom) {
                    resources.push(dicomSet.dicom);
                }
                if (dicomSet.thumbnail) {
                    thumbnails.push(dicomSet.thumbnail?.data.buffer ?? null);
                }
            } else if (isImageSingle(image)) {
                const imageStd = await this.unpackImageStd(image);
                if (imageStd.std) {
                    resources.push(imageStd.std);
                }
                if (imageStd.thumbnail) {
                    thumbnails.push(imageStd.thumbnail?.data.buffer ?? null);
                }
            } else if (isResource(image)) {
                switch (image.type) {
                    case 'video':
                        const resourceV = await this.unpackVideo(image);
                        if (resourceV.video) {
                            resources.push(resourceV.video);
                            thumbnails.push(null);
                        }
                        break;
                    case 'audio':
                        const resourceA = await this.unpackAudio(image);
                        if (resourceA.audio) {
                            resources.push(resourceA.audio);
                            thumbnails.push(null);
                        }
                        break;
                    case 'pdf':
                        const imagePdf = await this.unpackPdf(image);
                        if (imagePdf.pdf) {
                            resources.push(imagePdf.pdf);
                        }
                        if (imagePdf.thumbnail) {
                            thumbnails.push(imagePdf.thumbnail?.data.buffer ?? null);
                        }
                        break;
                    default:
                        console.error('UNKNOWN RESOURCE', image);
                        thumbnails.push(null);
                        resources.push({
                            id: '', 
                            iType: IType.Unknown,
                            data: [],
                            dataSize: [],
                            frames: 0,
                        });
                        break;
                }
            } else {
                console.error('UNKNOWN FILE TYPE');
                thumbnails.push(null);
                resources.push({
                    id: '',
                    iType: IType.Unknown,
                    data: [],
                    dataSize: [],
                    frames: 0,
                });
            }
        }
        return {thumbnails, resources};
    }

    public async unpackQuestions(used: Set<number>, questions: PractiqueNet.ExamJson.Definitions.Question[]): Promise<void> {
        for (const manifest of questions) {
            if (used.has(manifest.backend_id)) {
                //console.log('UNPACK', manifest.backend_id);
                try {
                    let thumbnails: (ArrayBuffer|null)[] = []
                    let images: Img[] = [];
                    const answersResources: AnswerResources[] = [];
                    if (manifest.images.length > 0) {
                        const unpacked = await this.unpackResources(manifest.images);
                        thumbnails = unpacked.thumbnails;
                        images = unpacked.resources;
                    }
                    if (manifest.answersResources) {
                        for (const packed of manifest.answersResources) {
                            const unpacked = await this.unpackResources(packed);
                            answersResources.push(unpacked);
                        }
                    }
                    await dbPut('questions', manifest.backend_id, {manifest, images, thumbnails, answersResources} as QuestionManifest);
                } catch (err) {
                    if (err instanceof Error) {
                        err.message = 'Question ' + manifest.backend_id + ', ' + err.message;
                        throw err;
                    } else {
                        throw new Error('Question ' + manifest.backend_id + ', ' + String(err));
                    }
                }
            }
        }
    }

    public async unpackManifest(): Promise<PractiqueNet.ExamJson> {
        const manifestEntry = this.files.get('manifest.json');
        if (!manifestEntry) {
            throw 'Exam file has no manifest.';
        }
        const data = await zipExtractText(manifestEntry);
        const maybeExam = safeJsonParse(data);
        if (!isExam(maybeExam)) {
            console.error('VALIDATION ERROR', examValidate.errors)
            console.error('EXAM DATA', maybeExam);
            throw 'exam validation error.';
        }
        return maybeExam;
    }
}

/*
var storeBlobs = (function() {
    function unpackExam(state: State, key: string, exam_id: string, chunkSize: number, size: number) {
        utils.log('BEGIN UNZIP');
        var t0 = performance.now();

        var unpack_progress = {
            ui : utils.make_elements(progress_ui),
        } as ProgressUi;
        unpack_progress.ui.title.textContent = 'Preparing exam, please wait...';
        unpack_progress.ui.subtext.textContent = 'Preparing can take several minutes depending on your computer specification.';
        unpack_progress.ui.text.textContent = '0%';
        wi.progress.appendChild(unpack_progress.ui.progress_box);
        function progress(v: number) {
            unpack_progress.ui.text.textContent = (100 * v | 0) + '%';
        }

        state.structure = [];
        return zip_get_entries(new IndexedDBReader('encrypted', key, exam_id, chunkSize, size)).then(function(entries) {
                var files = entries.reduce(function(map, obj) {
                    map.set(obj.filename, obj);
                    return map;
                }, new Map());
                var manifest_entry = files.get('manifest.json');
                return zip_extract_text(manifest_entry).then(function(data) {
                    var exam = JSON.parse(data);
                    if (!exam) {
                        throw 'manifest not found.';
                    }
                    utils.log('got manifest.');
                    if (exam.candidates.indexOf(state.exam_cid) < 0) {
                        throw 'candidate not found';
                    }
                    var len = count_exam_images(exam);
                    var idx = 1;
                    progress(idx / len);
                    if (exam.exam && exam.exam.answer_aes_key) {
                        state.this_exam = exam.exam;
                    } else {
                        state.this_exam = {'answer_aes_key': exam.answer_aes_key};
                    }
                    var max_heap_size = 0;
                    return exam.questions.reduce(function(promise1: Promise<void>, question: QuestionManifest, i: number) {
                        return promise1.then(function() {
                            question.id = i;
                            utils.log('BEGIN IMAGES');
                            var t2 = performance.now();
                            return question.images.reduce(function(promise2, image, j) {
                                return promise2.then(function() {
                                    if (image.set) {
                                        var db_offset = j;
                                        var dicom: dicom_viewer.DicomData = null;
                                        var data_size: number[] = [];
                                        var sizes: [number, number][] = [];
                                        var blob = new Blob([]);
                                        return image.set.reduce(function(promise3: Promise<number>, frame: string, k: number) {
                                            return promise3.then(function(cnt) {
                                                var entry = files.get(frame);
                                                return zip_extract_arraybuffer(entry).then(function(data) {
                                                    var new_dicom = dicom_viewer.make_dicom(data);
                                                    data = null;
                                                    if (!dicom || dicom_viewer.compatible(dicom, new_dicom)) {
                                                        dicom = new_dicom;
                                                        dicom.data.forEach(function(frame, x) {
                                                            data_size.push(frame.byteLength);
                                                            sizes.push([dicom.cols, dicom.rows]);
                                                            blob = new Blob([blob, frame]);
                                                            dicom.data[x] = null;
                                                            ++cnt;
                                                        })
                                                    }
                                                    progress(++idx / len);
                                                    return cnt;
                                                });
                                            });
                                        }, Promise.resolve(0)).then(function(cnt: number) {
                                            return storeImage([i, j], blob).then(function() {
                                                blob = null;
                                                max_heap_size = Math.max(max_heap_size, dicom_viewer.heap_size(dicom));
                                                dicom.data = [];
                                                dicom.frames = cnt;
                                                dicom.db_offset = db_offset;
                                                dicom.dataSize = data_size;
                                                dicom.sizes = sizes;
                                                dicom.cols = sizes[0][0];
                                                dicom.rows = sizes[0][1];
                                                question.images[j] = dicom;
                                                dicom = null;

                                            });
                                        });
                                    }
                                });
                            }, Promise.resolve()).then(function() {
                                var t3 = performance.now();
                                utils.log('MAX HEAP SIZE:', max_heap_size.toString(16));
                                utils.log('IMAGES TIME:', (t3 - t2), 'ms');
                            });
                        }).then(function() {
                            return store_question(question);
                        }).then(function() {
                            exam.questions[i] = null;
                        });
                    }, Promise.resolve()).then(function() {
                        //reader.close();
                        wi.progress.removeChild(unpack_progress.ui.progress_box);
                        state.question_count = exam.questions.length;
                        var t1 = performance.now();
                        utils.log('UNPACKING TIME: ', (t1 - t0), 'ms');
                        state.webcrypto_aes_key = null;
                        state.asmcrypto_aes_key = null;
                        return state;
                    });
                });
        }).catch(function(err) {
            wi.progress.removeChild(unpack_progress.ui.progress_box);
            utils.log('error: unpackExam:', err);
            state.webcrypto_aes_key = null;
            state.asmcrypto_aes_key = null;
            return Promise.reject(err);
        });
    }

    function getThumbnails(state) {
        if (state.question.images.length === 0) {
            utils.log('getThumbnails: no images');
            return Promise.resolve(state);
        }
        utils.log('getThumbnails: start images');
        return state.question.images.reduce(function(promise, image, j) {
            return promise.then(function() {
                utils.log('getThumbnails: ', [state.question.id, j], image.dataSize[0]);
                return db_get('images', [state.question.id, j]);
            }).then(function(blob) {
                return readBlob(blob.slice(0, image.dataSize[0])).then(function(buf) {
                    image.data.push(buf);
                });
            });
        }, Promise.resolve()).then(function() {
            utils.log('getThumbnails: end images');
            return state;
        });
    }

    function getImage(image) {
        var t0 = performance.now();
        return db_get('images', [state.question.id, image.db_offset]).then(function(blob) {
            var size = image.dataSize;
            return utils.fold(new utils.RangeIterator(0, image.frames), Promise.resolve(0), function(promise, frame, i) {
                return promise.then(function(start) {
                    var end = start + size;
                    return readBlob(blob.slice(start, end)).then(function(buf) {
                        image.data[frame] = buf;
                        return end;
                    });
                });
            });
        }).then(function() {
            var t1 = performance.now();
            utils.log('I [', state.question.id, image.db_offset, '] ', t1 - t0, 'ms');
        });
    }

    var blob;
    function getImageBegin(image) {
        return db_get('images', [state.question.id, image.db_offset]).then(function(b) {
            blob = b;
        });
    }

    function getImageFrame(image, frame) {
        var start = 0;
        for (var i = 0; i < frame; ++i) {
            start += image.dataSize[i];
        }
        var end = start + image.dataSize[frame];
        return readBlob(blob.slice(start, end))
    }

    function getImageEnd() {
        blob = null;
    }

    return {
        unpackExam : unpackExam,
        getThumbnails : getThumbnails,
        getImage : getImage,
        getImageBegin : getImageBegin,
        getImageFrame : getImageFrame,
        getImageEnd : getImageEnd
    }
})();
*/

//----------------------------------------------------------------------------
// Response Data Model

export type Json = string | number | boolean | null | Json[] | { [key: string]: Json | undefined };

function isJson(x: unknown): x is Json {
    return typeof x === 'string' ||
        typeof x === 'number' ||
        typeof x === 'boolean' ||
        (typeof x === 'object' && x === null) ||
        isIndexed(x);
}

export enum ResponseStatus {
    emptyLocal = 'EMPTY_LOCAL',
    savedLocal = 'SAVED_LOCAL',
    emptyRemote = 'EMPTY_REMOTE',
    savedRemote = 'SAVED_REMOTE'
}

export interface LocalData extends AnswerValue {
    elapsed: number;
    timeOnQuestion?: number;
}

export interface RemoteData extends LocalData {
    exam: string;
    responder: string;
    question: number;
    response: number;
    factor?: string;
}

function isLocalData(x: unknown): x is LocalData {
    return isIndexed(x) && 
        typeof x.elapsed === 'number' &&
        (typeof x.answer === 'undefined' || isJson(x.answer)) &&
        (typeof x.extra === 'undefined' || isJson(x.extra));
}

interface Flags {
    qid: number;
    aid: number;
    flag: boolean;
}

type Expr = PractiqueNet.ExamJson.Definitions.Expr;

interface StatusResponse {
    state?: ExamState;
    elapsed?: number;
    schedule?: PractiqueNet.ExamJson.Definitions.Schedule;
    responses?: unknown[];
    returnResponses?: boolean;
}

export type RemoteStatus = Omit<StatusResponse, 'responses'>;

export function isStatusResponse(x: unknown): x is StatusResponse {
    return isIndexed(x) &&
        (typeof x.state === 'undefined' || typeof x.state === 'string' && isState(x.state)) &&
        (typeof x.elapsed === 'undefined' || typeof x.elapsed === 'number') &&
        (typeof x.responses === 'undefined' || Array.isArray(x.responses)) &&
        (typeof x.schedule === 'undefined' || isSchedule(x.schedule));
}

function isRemoteData(x: unknown): x is RemoteData {
    return isIndexed(x) && isLocalData(x) &&
        typeof x.exam === 'string' &&
        typeof x.responder === 'string' &&
        typeof x.question === 'number' &&
        typeof x.response === 'number' &&
        (typeof x.factor === 'undefined' || typeof x.factor === 'string');
}

export class ResponseModel {
    private examId: string;
    private candidateId: string;
    private items: Structure[];
    private demo: boolean;

    constructor(examId: string, candidateId: string, demo: boolean, items: Structure[]) {
        this.examId = examId;
        this.candidateId = candidateId;
        this.items = items;
        this.demo = demo;
    }

    remote({itemIndex, fieldIndex, local} : {itemIndex: number, fieldIndex: number, local: LocalData}): RemoteData {
        return {
            exam: this.examId,
            responder: this.candidateId,
            question: this.items[itemIndex].backendQid[0], // aways use primary vairant
            response: fieldIndex < 0 ? 0 : this.items[itemIndex].backendAid[fieldIndex],
            ...local,
        };
    }

    async postAnswer(itemIndex: number, fieldIndex: number, local: LocalData): Promise<void> {
        if (this.demo) {
            return;
        }
        const remval = this.remote({itemIndex, fieldIndex, local})
        const factor = this.items[itemIndex].factor;
        if (factor != undefined) {
            remval.factor = factor;
        }
        try {
            await postJson(
                remval,
                '/app/answers/'
                + remval.exam + '/'
                + remval.responder + '/'
                + remval.question + '/'
                + remval.response
            );
        } catch(err) {
            if (!(err instanceof HttpError) || err.status != 404) {
                throw err;
            } else {
                console.error('POST_ANSWER', String(err));
            }
        }
    }
    
    async setAnswer({qno, ano}: {qno: number, ano: number}, value: LocalData, onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<boolean> {
        const key = [this.examId, this.candidateId, qno, ano];
        console.debug('SAVE:', key, '=', value);
        try {
            await dbPut('answers', key, value);
            const st1 = (value.answer === null) ? ResponseStatus.emptyLocal : ResponseStatus.savedLocal;
            await dbPut('status', key, st1);
            onStatus(qno, ano, st1);
            await this.postAnswer(qno, ano, value);
            const st2 = (value.answer === null) ? ResponseStatus.emptyRemote : ResponseStatus.savedRemote;
            await dbPut('status', key, st2);
            onStatus(qno, ano, st2);
            return true;
        } catch (err) {
            console.error('SET_ANSWER', String(err));
            return false;
        }
    }

    async getAnswer(itemIndex: number, fieldIndex: number): Promise<LocalData|undefined> {
        const key = [this.examId, this.candidateId, itemIndex, fieldIndex];
        const response = await dbGet('answers', key);
        if (isLocalData(response)) {
            return response;
        } else {
            return undefined;
        }
    }

    async getLastQuestion(): Promise<LocalData|undefined> {
        let last: LocalData | undefined;
        await dbCursor('answers', IDBKeyRange.bound(
            [this.examId, this.candidateId, 0, -1],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, -1]
        ), cursor => {
            // NOTE: We have to check last key element is '-1' because IndexedDB Array keys are compared left to right.
            if (Array.isArray(cursor.key) && cursor.key.length >= 4 && cursor.key[3] === -1 && isLocalData(cursor.value)) {
                if (last === undefined || cursor.value.elapsed > last.elapsed) {
                    last = cursor.value;
                }
            }
        });
        return last;
    }

    async resendAnswer(itemIndex: number, fieldIndex: number, onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<boolean> {
        const key = [this.examId, this.candidateId, itemIndex, fieldIndex];
        console.debug('RESEND:', key);
        try {
            const answer = await dbGet('answers', key);
            if (isLocalData(answer)) {
                await this.postAnswer(itemIndex, fieldIndex, answer);
                const st2 = (answer === null) ? ResponseStatus.emptyRemote : ResponseStatus.savedRemote;
                await dbPut('status', key, st2);
                onStatus(itemIndex, fieldIndex, st2);
            }
            return true;
        } catch (err) {
            alert(translate('ERROR_RESEND', {err: String(err)}));
            return false;
        }
    }

    async fetchStatus(localState: StatusResponse): Promise<StatusResponse|undefined> {
        if (this.demo) {
            return {};
        }
        try {
            console.warn('LOCAL STATUS SENT', localState);
            const statusResponse = await requestJson(urlWithCredentials(`/app/${this.examId}/status/`), localState);
            console.warn('REMOTE STATUS RECV', statusResponse);
            if (isStatusResponse(statusResponse)) {
                return statusResponse;
            }
        } catch (err) {
            console.error('FETCH_STATUS', err.message ?? err);
            return undefined;
        }
        return {};
    }

    async updateResponses({responses}: StatusResponse): Promise<void> {
        if (responses) {
            for (const item of responses) {
                if (isRemoteData(item)) {
                    const {exam, responder, question, response, factor, ...value} = item;
                    if (exam === this.examId && responder === this.candidateId) {
                        const qno = this.items.findIndex(x => x.backendQid[0] === question && (factor == undefined || x.factor === factor));
                        const ano = item.response === 0 ? -1 : this.items[qno].backendAid.indexOf(response);
                        const key = [exam, responder, qno, ano];
                        await dbPut('answers', key, value);
                        const status = (item.answer) ? ResponseStatus.savedRemote : ResponseStatus.emptyRemote;
                        await dbPut('status', key, status);
                    }
                }
            }
        }
    }

    async getStatus(onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<void> {
        await dbCursor('status', IDBKeyRange.bound(
            [this.examId, this.candidateId, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        ), cursor => {
            const key = cursor.key as [string, string, number, number];
            onStatus(key[2], key[3], cursor.value);
        });
    }

    async getFlags(): Promise<Flags[]> {
        const items: Flags[] = [];
        await dbCursor('flags', IDBKeyRange.bound(
            [this.examId, this.candidateId, 0, 0],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        ), cursor => items.push(cursor.value));
        return items;
    }

    async setFlag(qno: number, ano: number, flag: boolean): Promise<void> {
        return dbPut('flags', [this.examId, this.candidateId, qno, ano] as IDBArrayKey, {
            qid: qno,
            aid: ano,
            flag: flag
        });
    }

    async resendUnsent(onStatus: (i: number, j: number, onStatus: ResponseStatus) => void): Promise<boolean> {
        const unsent: {i: number, j: number}[] = [];
        await this.getStatus((i, j, status) => {
            switch (status) {
                case ResponseStatus.emptyLocal:
                case ResponseStatus.savedLocal:
                    unsent.push({i, j});
                    break;
                default:
                    break;
            }
        });
        let succ = true;
        for (const {i, j} of unsent) {
            succ = succ && await this.resendAnswer(i, j, onStatus);
        }
        return succ;
    }
}

