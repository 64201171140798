
export interface ReconnectingEventSourceConfiguration extends EventSourceInit {
    lastEventId?: string;
    retryTime?: number;
}

export type EventSourceListener = (event: MessageEvent<string>) => void; 

export class ReconnectingEventSource {
    public static readonly CONNECTING = EventSource.CONNECTING;
    public static readonly OPEN = EventSource.OPEN;
    public static readonly CLOSED = EventSource.CLOSED;

    private url: URL;
    private lastEventId?: string;
    private configuration: EventSourceInit = {};
    private eventSource?: EventSource;
    private timer?: number;
    private listeners = new Map<keyof EventSourceEventMap, EventSourceListener[]>();
    private esReadyState = EventSource.CONNECTING;
    private retryTime = 3000;

    get readyState(): number {
        return this.esReadyState;
    }

    constructor(url: URL, configuration?: ReconnectingEventSourceConfiguration) {
        this.url = url;
        if (configuration) {
            if (configuration.lastEventId) {
                this.lastEventId = configuration.lastEventId;
            }
            if (configuration.retryTime) {
                this.retryTime = configuration.retryTime;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {lastEventId: _a, retryTime: _b, ...esconf} = configuration;
            this.configuration = esconf;
        }

        this.connect();
    }

    private connect() {
        const url = new URL(String(this.url));
        if (this.lastEventId) {
            url.searchParams.append('lastEventId', this.lastEventId);
        }
        this.eventSource = new EventSource(String(url), this.configuration)
        this.eventSource.onopen = this.handleOpen;
        this.eventSource.onerror = this.handleError;
        for (const type of Array.from(this.listeners.keys())) {
            this.eventSource.addEventListener(type, this.handleEvent);
        }
    }

    private readonly handleConnect = (): void => {
        this.connect();
    }

    private readonly handleOpen = (event: Event): void => {
        if (this.esReadyState === EventSource.CONNECTING) {
            this.esReadyState = EventSource.OPEN;
            this.onopen(event);
        }
    }

    private readonly handleError = (event: Event): void => {
        if (this.esReadyState === EventSource.OPEN) {
            this.esReadyState = EventSource.CONNECTING;
            this.onerror(event);
        }
    
        if (this.eventSource) {
            if (this.eventSource.readyState === ReconnectingEventSource.CLOSED) {
                this.eventSource.close();
                this.eventSource = undefined;
                this.timer = window.setTimeout(this.handleConnect, this.retryTime);
            }
        }
    };

    private readonly handleEvent = (event: Event|MessageEvent<keyof EventSourceEventMap>): void => {
        if (event instanceof MessageEvent) {
            if (event.lastEventId) {
                this.lastEventId = event.lastEventId;
            }

            let listeners = this.listeners.get(event.type as keyof EventSourceEventMap);
            if (listeners) {
                listeners = listeners.slice();
                for (let i = 0; i < listeners.length; ++i) {
                    listeners[i](event);
                }
            }
            if (event.type === 'message') {
                this.onmessage(event);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onopen: (event: Event) => void = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onerror: (event: Event) => void = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onmessage: (event: MessageEvent<string>) => void = () => {};

    public close(): void{
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
        
        if (this.eventSource) {
            for (const type of Array.from(this.listeners.keys())) {
                this.eventSource.removeEventListener(type, this.handleEvent);
            }
            this.eventSource.close();
            this.eventSource = undefined;
        }

        this.esReadyState = EventSource.CLOSED;
    }

    public addEventListener(type: keyof EventSourceEventMap, callback: EventSourceListener): void {
        let listeners = this.listeners.get(type);
        if (!listeners) {
            listeners = [];
            this.listeners.set(type, listeners);
            if (this.eventSource) {
                this.eventSource.addEventListener(type, this.handleEvent);
            }
        }
        for (let i = 0; i < listeners.length; ++i) {
            if (listeners[i] === callback) {
                return;
            }
        }
        listeners.push(callback);
    }

    public removeEventListener(type: keyof EventSourceEventMap, callback:EventSourceListener): void {
        const listeners = this.listeners.get(type);
        if (!listeners) {
            return;
        }
        for(let i = 0; i < listeners.length; ++i) {
            if (listeners[i] === callback) {
                listeners.splice(i, 1);
                break;
            }
        }
        if (listeners.length === 0) {
            this.listeners.delete(type)
            if (this.eventSource) {
                this.eventSource.removeEventListener(type, this.handleEvent);
            }
        }
    }
}