import autosize from 'autosize';
import { LocalData } from 'exam-service';
import { mkNode, scrollRangeIntoView, removeNode } from 'utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerResources, AnswerKey, AnswerValue } from 'question-base';
import { Lightbox } from 'lightbox';
import { configSafeTextBox } from 'exam-accessibility';

/** Longtext question textarea UI */
export class QuestionText extends QuestionBase implements Question {
    //private ai: LongtextUi;

    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private textarea: HTMLTextAreaElement | HTMLInputElement;
    private wordcount: HTMLDivElement;
    private enableCopyPaste: boolean;

    private isLong: boolean;
    private value: string;
    private updateVisibility: () => void;
    private autosave?: number;

    public readonly visibilityExpression?: Expr;

    /** Construct LontextQuestion UI */
    public constructor(
        updateVisibility: () => void,
        context: QuestionContext,
        qno: number,
        ano: number, 
        backendQid: number,
        backendAid: number,
        showNumber: boolean,
        label: string,
        frag: DocumentFragment,
        isLong: boolean,
        lightbox: Lightbox,
        isRosceCandidate: boolean,
        minLines?: number,
        notes?: string,
        indent?: number,
        visibilityExpression?: Expr,
        resources?: AnswerResources
    ) {
        super(context, frag, qno, ano, backendQid, backendAid, showNumber, true, label, lightbox, isRosceCandidate, notes, resources);
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`; 
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        if (isLong) {
            this.textarea = mkNode('textarea', {className: 'long-answer ' + configSafeTextBox, parent: this.answerItem, 
                attrib:{rows: minLines ? minLines.toString() : '3', autocomplete: "off", autocorrect: "off", autocapitalize: "off", spellcheck: "false"}
            });
        } else {
            this.textarea = mkNode('input', {className: 'short-answer ' + configSafeTextBox, parent: this.answerItem, 
                attrib:{type: 'text', autocomplete: 'off', autocorrect: 'off', autocapitalize: 'off', spellcheck: 'false'}
            });
        }
        this.wordcount = mkNode('div', {className: 'wordcount', parent: this.answerItem});
        this.wordcount.innerHTML = '_ words';
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        //this.textarea.name = label;
        this.textarea.disabled = true;
        this.value = '';
        this.isLong = isLong;
        this.enableCopyPaste = context.meta.enableCopyPaste ?? false;
        //frag.appendChild(this.answerItem);
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            if (response && typeof response.answer === 'string') {
                this.value = response.answer;
                this.textarea.value = response.answer;
            }
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        if (this.isLong) {
            autosize(this.textarea);
        }
        this.textarea.disabled = this.isLoading || this.isReadOnly;
        this.textarea.addEventListener('change', this.change_handler);
        this.textarea.addEventListener('input', this.update);
        if (this.isLong) {
            this.autosave = window.setInterval(this.change_handler, 30000);
        }
        if (!this.enableCopyPaste) {
            this.textarea.addEventListener('paste', this.paste_handler);
            this.textarea.addEventListener('drop', this.paste_handler);
        }
        this.update();
    }

    public setReadOnly(isReadOnly: boolean): void {
        super.setReadOnly(isReadOnly);
        this.textarea.disabled = this.isLoading || this.isReadOnly;
    }

    /** Get the answer value */
    public getValue(): string {
        return this.value;
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        if (this.autosave) {
            window.clearInterval(this.autosave);
            this.autosave = undefined;
        }
        this.textarea.removeEventListener('change', this.change_handler);
        this.textarea.removeEventListener('input', this.update);
        if (!this.enableCopyPaste) {
            this.textarea.removeEventListener('paste', this.paste_handler);
            this.textarea.removeEventListener('drop', this.paste_handler);
        }
        if (this.isLong) {
            autosize.destroy(this.textarea);
        }
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
        this.textarea.focus();
    }

    private readonly update = (): void => {
        const l = (this.textarea.value.match(/\w+/g) ?? []).length;
        this.wordcount.innerHTML = l + ((l == 1) ? ' word' : ' words');
    }

    public getAnswer(): AnswerKey & AnswerValue {
        return {qno: this.qno, ano: this.ano, answer: this.value || null};
    }

    private readonly change_handler = async (): Promise<void> => {
        if (this.value != this.textarea.value) {
            const value = this.textarea.value;
            this.value = value;
            try {
                await this.context.saveAnswer({qno: this.qno, ano: this.ano}, {answer: value || null});
            } catch(e) {
                console.error(e.toSting());
            } finally {
                this.updateVisibility();
            }
        }
    }

    private readonly paste_handler = (event: Event): void => {
        event.preventDefault();
    }
}

registerAnswerType({
    name: 'Text',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'longtext' || answer.type.toLowerCase() === 'text';
    },
    makeAnswer: (
        pos: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerLongtext,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRosceCandidate: boolean,
    ): Question => {
        return new QuestionText(
            updateVisibility,
            context,
            pos,
            ano,
            question.manifest.backend_id,
            answer.backend_id,
            question.manifest.answers.length > 1,
            answer.label,
            frag,
            answer.type.toLowerCase() === 'longtext',
            lightbox,
            isRosceCandidate,
            answer.minimumLines,
            answer.notes,
            answer.indent,
            answer.visible,
            question.answersResources[ano],
        );
    }
});
