import { NumberOfConflicts } from 'aws-sdk/clients/codecommit';
import { ControlPanel, NotificationArea } from 'question-base';
import { dbPut } from 'utils-db';
import { translate } from 'utils-lang';
import { isIndexed, mkNode } from './utils';

function isScheduleItem(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleItem {
    return isIndexed(x) &&
        typeof x.type === 'string' && x.type === 'item' &&
        typeof x.time === 'number' &&
        typeof x.item === 'number' && 
        (typeof x.duration === 'number' || typeof x.duration === "undefined");
}

function isScheduleMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleMessage {
    return isIndexed(x) && 
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'message' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isScheduleTimedMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleTimedMessage {
    return isIndexed(x) && 
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'timedMessage' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isConditionalMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleConditionalMessage {
    return isIndexed(x) && 
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'conditionalMessage' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isReadOnly(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleReadOnly {
    return isIndexed(x) &&
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'read-only';
}

function isReadWrite(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleReadWrite {
    return isIndexed(x) &&
    typeof x.time === 'number' &&
    (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
    typeof x.type === 'string' && x.type === 'read-write';
}

export function isSchedule(x: unknown): x is PractiqueNet.ExamJson.Definitions.Schedule {
    if (Array.isArray(x)) {
        for (const item of x) {
            if (!(isScheduleItem(item) || isScheduleMessage(item) || isScheduleTimedMessage(item) || isConditionalMessage(true) || isReadOnly(item) || isReadWrite(item))) {
                return false;
            }
        }
        return true;
    }
    return false;
}

function formatTime(t: number): string {
    const n = Math.floor(t) < 0;
    const h = Math.floor(Math.abs(t) / 3600);
    const m = Math.floor(Math.abs(t) / 60) - h * 60;
    const s = Math.floor(Math.abs(t)) - h * 3600 - m * 60;
    const H = String(h);
    return `${n ? '-':''}${H.length < 2 ? '0' + H : H}:${('0' + m).slice(-2)}:${('0' + s).slice(-2)}`;
}

export class ExamTimer {
    private readonly callback: () => Promise<void>;
    private readonly controlPanel: ControlPanel;
    private readonly notificationArea: NotificationArea;
    private readonly timeText: HTMLSpanElement;
    private readonly timeLabel: HTMLSpanElement;
    private readonly timer: HTMLButtonElement;
    private readonly down: boolean;
    private elapsedSeconds = 0;
    private currentTime = 0;
    private duration: number;
    private startSeconds: number;
    private interval?: number;
    private schedule: PractiqueNet.ExamJson.Definitions.Schedule;
    private isReadOnly = false;
    private updating = false;
    private itemRemaining: {[item:number]: {time: number, timestamp: number}} = {};

    public async update() {
        if (this.updating) {
            return;
        }
        try {
            this.updating = true;
            //this.timeText.textContent = formatTime(this.down ? this.duration - this.currentTime : this.currentTime);
            if (this.interval) {
                let readOnly = false;
                const messages: string[] = []
                let itemContext: number|undefined;
                let itemRemaining: number|undefined;
                for (const item of this.schedule) {
                    switch (item.type) {
                        case 'conditionalMessage':
                            if (!this.after(item.time)) {
                                const {condition, item: currentItem} = this.notificationArea.check('connected');
                                if (itemContext === currentItem) {
                                    const remaining = this.itemRemaining[currentItem];
                                    if (remaining && remaining.time > 0) {
                                        const remainingTime = condition ? remaining.time - (this.currentTime - remaining.timestamp) : remaining.time;
                                        this.itemRemaining[itemContext] = {time: remainingTime, timestamp: this.currentTime};
                                        messages.push(`${item.value} ${formatTime(remainingTime)}`);
                                    } else if (remaining === undefined) {
                                        this.itemRemaining[itemContext] = {time: item.duration, timestamp: this.currentTime};
                                        messages.push(`${item.value} ${formatTime(item.duration)}`);
                                    } else if (item.expired !== undefined) {
                                        messages.push(item.expired);
                                    }
                                }
                            }
                            break;
                        case 'timedMessage': { // show scheduled message
                            if (itemContext !== undefined && itemContext !== this.notificationArea.check().item) {
                                break;
                            }
                            const remaining = this.inRange(item.time, item.duration ?? 60);
                            if (remaining > 0) {
                                messages.push(`${item.value} ${formatTime(remaining + 1)}`);
                            }
                            break;
                        }
                        case 'message': {
                            if (itemContext !== undefined && itemContext !== this.notificationArea.check().item) {
                                break;
                            }
                            const remaining = this.inRange(item.time, item.duration ?? 60);
                            if (item.value && remaining > 0) {
                                messages.push(item.value);
                            }
                            break;
                        }
                        case 'item': { // restrict to selected item
                            const remaining = this.inRange(item.time, item.duration ?? 60);
                            if (item.item !== undefined) {
                                itemContext = item.item;
                                if (remaining > 0) {
                                    this.notificationArea.item(itemContext); // async
                                    itemRemaining = remaining + 1;
                                }
                            }
                            break;
                        }
                        case 'read-only': { // restrict to read-only
                            if (itemContext !== undefined && itemContext !== this.notificationArea.check().item) {
                                break;
                            }
                            const remaining = this.inRange(item.time, item.duration ?? 60);
                            if (remaining > 0) {
                                readOnly = true;
                            }
                            break;
                        }
                        case 'read-write':
                            break;
                        default:
                            console.error('UNSUPPORTED SCHEDULE ITEM', item);
                            break;
                    }
                }

                if (itemRemaining !== undefined) {
                    this.timeText.textContent = formatTime(itemRemaining);
                    this.timeLabel.textContent = translate('TIMER_ROUND_REMAINING');
                } else {
                    this.timeText.textContent = formatTime(this.down ? this.duration - this.currentTime : this.currentTime);
                    this.timeLabel.textContent = this.down ? translate('TIMER_REMAINING') : translate('TIMER_ELAPSED');
                }

                if (messages.length > 0) {
                    this.notificationArea.show(messages.join('<br>'));
                } else {
                    this.notificationArea.hide();
                }
                if (readOnly !== this.isReadOnly) {
                    this.notificationArea.setReadOnly(readOnly);
                    this.isReadOnly = readOnly;
                }
            }
        } finally {
            this.updating = false;
        }
    } 

    private inRange(time: number, duration: number): number {
        const t = (time < 0) ? this.currentTime - this.duration - time : time + duration - this.currentTime;
        return t <= duration ? t : duration - t; //>= 0 ? duration - t : t; //0 <= t && t < duration;
    }

    private after(time: number): boolean {
        return 0 <= ((time < 0) ? this.currentTime - time : time - this.currentTime);
        //const t = (time < 0) ? this.currentTime - time : time - this.currentTime;
        //return t;
    }

    private readonly handleInterval = () => {
        this.currentTime = Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
        if (this.duration > 0 && this.currentTime >= this.duration) {
            this.currentTime = this.duration;
            this.update();
            this.callback();
        } else {
            this.update();
        }
    }

    private getDuration(): number {
        let duration = 0;
        for (const item of this.schedule) {
            switch (item.type) {
                case 'read-only':
                case 'read-write':
                    duration += item.duration ?? 60;
                    break;
                default:
                    break;
            }
        }
        return duration;
    }

    constructor({controlPanel, notificationArea, timing, schedule, callback}: {
        controlPanel: ControlPanel,
        notificationArea: NotificationArea,
        timing?: PractiqueNet.ExamJson.Definitions.Timing,
        schedule: PractiqueNet.ExamJson.Definitions.Schedule, 
        callback: () => Promise<void>,
    }) {
        this.controlPanel = controlPanel;
        this.notificationArea = notificationArea;
        this.callback = callback;
        this.timeText = mkNode('span', {className: 'app-text'});
        this.timeLabel = mkNode('span', {className: 'app-button-text'});
        this.timer = mkNode('button', {
            className: 'app-button',
            attrib: {disabled: 'true'},
            children: [this.timeText, this.timeLabel],
        });
        this.down = timing ? timing.counter === 'down' : false;
        this.startSeconds = Date.now() / 1000;
        this.schedule = schedule;
        this.duration = this.getDuration();
        this.update();
        this.controlPanel.add(this.timer);
    }

    public getElapsed(): number {
        return Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
    }

    public setElapsed(seconds: number): void {
        this.elapsedSeconds = seconds;
        this.startSeconds = Date.now() / 1000;
        this.currentTime = this.elapsedSeconds;
        this.update();
    }

    public async setSchedule(schedule: PractiqueNet.ExamJson.Definitions.Schedule): Promise<void> {
        this.schedule = schedule;
        this.duration = this.getDuration();
        this.update();
        await dbPut('users', 'schedule', this.schedule);
    }

    public start(): void {
        if (!this.interval) {
            this.startSeconds = Date.now() / 1000;
            this.interval = window.setInterval(this.handleInterval, 1000);
            this.currentTime = this.elapsedSeconds;
            this.update();
        }
    }

    public stop(): void {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = undefined;

            this.elapsedSeconds += Date.now() / 1000 - this.startSeconds;
            this.currentTime = this.elapsedSeconds;
            this.update();
        }
    }

    public async destroy(): Promise<void> {
        this.controlPanel.remove(this.timer);
    }
}