import { mkNode } from './utils';
import { Thumbnail, ThumbnailBase } from './thumbnail-base';
import { Img } from './image-base';
import { rendererFromImage } from './image-base';
import { DicomViewer } from './image-viewer';


export class ResourceThumbnail extends ThumbnailBase implements Thumbnail {
    private thumb: HTMLCanvasElement;
    private cols: number;
    private rows: number;

    public constructor(image: Img, frag: Node, releaseButton = false) {
        const cols = (image.cols != undefined && image.rows != undefined) ? Math.floor(120 * image.cols / image.rows) : 120;
        const rows = 120;
        let thumb;
        super(frag, image, releaseButton, () => {
            thumb = mkNode('canvas', {
                className: 'thumbnail', 
                parent: this.figure,
                style: {visibility: 'hidden', width: cols + 'px', height: rows + 'px'},
                attrib: {title: 'Click on the thumbnail to view and manipulate the image.', draggable: 'false'},
            });
        });
        this.cols = cols;
        this.rows = rows;
        if (!thumb) {
            throw "Thumbnail canvas is null.";
        }
        this.thumb = thumb;
    }

    public async load(thumbnail: ArrayBuffer|null): Promise<void> {
        if (thumbnail) {
            try {
                const cxt = this.thumb.getContext('2d');
                if (cxt != null) {
                    this.thumb.width = this.cols;
                    this.thumb.height = this.rows;
                    if (thumbnail) {
                        const uint8clamped = new Uint8ClampedArray(thumbnail);
                        const imageData = new ImageData(uint8clamped, this.cols, this.rows);
                        cxt.putImageData(imageData, 0, 0);
                    }
                    this.thumb.style.visibility = 'visible';
                }
            } catch (e) {
                console.error('THUMBNAIL LOAD ERROR', e);
            }
        }
    }

    public async open(makeViewer: () => DicomViewer): Promise<void> {
        await makeViewer().setDicom(rendererFromImage(this.image));
    } 

    public destroy(): void {
        super.destroy();
        console.log('thumbnail: destroy');
    }
}
