import { dbPut, dbGet } from "utils-db";


type Dict<T> = {[key:string]:T};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslationArgs = Dict<any>;
type TranslationFn = (args:TranslationArgs) => string;
type Translations = Dict<string|TranslationFn>;

let available: string[] = [];
let languages: Array<Translations> = [];
let currentLanguage: number|null = null;

export async function initTranslation(): Promise<void> {
    const savedLanguage = await dbGet('session', 'ui-language');
    if (typeof savedLanguage === 'string') {
        currentLanguage = available.indexOf(savedLanguage);
    }
    currentLanguage = currentLanguage ?? available.indexOf('English') ?? 0;
}

export async function nextLanguage(): Promise<void> {
    if (currentLanguage !== null) {
        currentLanguage++;
        if (currentLanguage >= languages.length) {
            currentLanguage = 0;
        }
        await dbPut('session','ui-language', available[currentLanguage]);
    }
}

export function translate(key: string, args?:TranslationArgs): string {
    if (currentLanguage === null) {
        return `[${key}]`
    }
    const t = languages?.[currentLanguage]?.[key] ?? `[${key}]`;
    return (typeof t === 'function') ? t(args ?? {}) : t;
}

export function localise(val: number): string {
    if (currentLanguage) {
        return val.toLocaleString(translate('LOCALE'));
    } else {
        return val.toString();
    }
}

export function registerLanguage(lang: string, dict: Dict<string|TranslationFn>): void {
    console.info('REGISTER_LANGUAGE', lang);
    available.push(lang);
    languages.push(dict);
}


