//import { asm, nextValidHeapSize } from '../static/asm-render';
import {
    DataSet, 
    parseDicom,
    createJPEGBasicOffsetTable,
    readEncapsulatedImageFrame,
    readEncapsulatedPixelDataFromFragments,
    Element as PixelDataElement,
} from 'dicom-parser';
import { Transform } from '../utils';
import { Canvas, createCanvas, createImageData, CanvasRenderingContext2D } from 'canvas';
import * as jpeg from 'jpeg-lossless-decoder-js';
import { Img, IType, Renderer, registerRendererType} from '../image-base';

 
declare interface Tile {
    items: Int16Array | Uint16Array | Int8Array | Uint8Array;
}

declare class JpxImage {
    public parse(data: Uint8Array): void;
    public failOnCorruptedImage: boolean;
    public width: number;
    public height: number;
    public componentsCount: number;
    public tiles: Tile[];
}

function lut8unsigned(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? (-pmin) * pscl : 0;
    while (x < pmin) {
        lut[x++] = 0;
    }
    while (x < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 256) {
        lut[x++] = 255;
    }
}

function lut8inverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 255;
    let y = (pmin < 0) ? 255 + pmin * pscl : 255;
    while (x < pmin) {
        lut[x++] = 255;
    }
    while (x < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 256) {
        lut[x++] = 0;
    }
}

function lut16unsigned(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? (-pmin) * pscl : 0;
    while (x < pmin) {
        lut[x++] = 0;
    }
    while (x < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 65536) {
        lut[x++] = 255;
    }
}

function lut16inverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? 255 + pmin * pscl : 255;
    while (x < pmin) {
        lut[x++] = 255;
    }
    while (x < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 65536) {
        lut[x++] = 0;
    }
}

function lut16signed(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < -32768) ? (-32768 - pmin) * pscl : 0;
    while (x - 32768 < pmin) {
        lut[x++] = 0;
    }
    while (x - 32768 < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 65536) {
        lut[x++] = 255;
    }
}

function lut16signedInverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < -32768) ? 255 + (32768 + pmin) * pscl : 255;
    while (x - 32768 < pmin) {
        lut[x++] = 255;
    }
    while (x - 32768 < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 65536) {
        lut[x++] = 0;
    }
}

function applyLut8(src: Uint8Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i]];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut16(src: Uint16Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i]];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut16signed(src: Int16Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i] + 32768];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut8rgb(src: Uint8Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    let p = 0;
    for (let i = 0; i < n; ++i) {
        dst[i] = lut[src[p++]] | lut[src[p++]] << 8 | lut[src[p++]] << 16 | 0xff000000;
    }
}

type RenderFn = (pmin: number, pmax: number, pscl: number, n: number, src: ArrayBuffer) => Uint8ClampedArray;
type RenderFactory = (lut: ArrayBuffer, dst: ArrayBuffer) => RenderFn;

interface RenderFactories {
    render8: RenderFactory;
    render8inverted: RenderFactory;
    render16: RenderFactory;
    render16inverted: RenderFactory;
    render16signed: RenderFactory;
    render16signedInverted: RenderFactory;
    render8rgb: RenderFactory;
    unsupported: (dst: ArrayBuffer) => (pmin: number, pmax: number, pscl: number, n: number, src: ArrayBuffer) => Uint8ClampedArray;
}

const renderFns: RenderFactories = {
    render8: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8unsigned(pmin, pmax, pscl, lut8);
            applyLut8(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render8inverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8inverted(pmin, pmax, pscl, lut8);
            applyLut8(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16unsigned(pmin, pmax, pscl, lut8);
            applyLut16(new Uint16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16inverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16inverted(pmin, pmax, pscl, lut8);
            applyLut16(new Uint16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16signed: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16signed(pmin, pmax, pscl, lut8);
            applyLut16signed(new Int16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16signedInverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16signedInverted(pmin, pmax, pscl, lut8);
            applyLut16signed(new Int16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render8rgb: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8unsigned(pmin, pmax, pscl, lut8);
            applyLut8rgb(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    unsupported: (dst) => {
        const dst32 = new Uint32Array(dst);
        return (_pmin, _pmax, _pscl, n) => {
            for (let i = 0; i < n; ++i) {
                dst32[i] = 0xff0000ff;
            }
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
};


//------------------------------------------------------------------------
// Support for DICOM format images.

// BEGIN: PATCH DICOM PARSER

/*
dicomParser.isEncapsulated = function(_element, byteStream) {
    switch(byteStream.transferSyntax) {
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70':
        case '1.2.840.10008.1.2.4.90':
            const tag = dicomParser.readTag(byteStream);
            byteStream.seek(-4);
            return tag === 'xfffee000';
        default:
            return false;
    }
}
*/

// END: PATCH DICOM PARSER

function getData(dataSet: DataSet, pixelDataElement: PixelDataElement, len: number, frame: number, frames: number): ArrayBuffer {
    if (pixelDataElement && pixelDataElement.encapsulatedPixelData) {
        if (!pixelDataElement.basicOffsetTable?.length) {
            if (frames != pixelDataElement.fragments?.length) {
                const basicOffsetTable = createJPEGBasicOffsetTable(dataSet, pixelDataElement);
                const data = readEncapsulatedImageFrame(dataSet, pixelDataElement, frame, basicOffsetTable);
                return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
            }
            const data = readEncapsulatedPixelDataFromFragments(dataSet, pixelDataElement, frame);
            return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
        } 
        const data = readEncapsulatedImageFrame(dataSet, pixelDataElement, frame);
        return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
    }

    const offset = frame * len + pixelDataElement.dataOffset;
    return dataSet.byteArray.buffer.slice(offset, offset + len);
}

function getPixelData(
    dataSet: DataSet,
    transferSyntax: string,
    _bytes: number,
    len: number,
    frame: number,
    frames: number,
): ArrayBuffer {
    const pixelDataElement = dataSet.elements.x7fe00010;
    const buffer = getData(dataSet, pixelDataElement, len, frame, frames);

    switch(transferSyntax) {
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70': {
            console.log('JPEG');
            const decoder = new jpeg.lossless.Decoder();
            return decoder.decompress(buffer, 0, buffer.byteLength);
        }
        case '1.2.840.10008.1.2.4.90': {
            console.info('JP2');
            const data = new Uint8Array(buffer);
            console.info('DATA_SIZE', data.length);
            const signature = [data[0].toString(16), data[1].toString(16), data[2].toString(16), data[3].toString(16),
                data[4].toString(16), data[5].toString(16), data[6].toString(16), data[7].toString(16)].join(' ');
            console.info('IS_J2K: ', signature);
            const decoder = new JpxImage();
            decoder.parse(data);
            //console.log('DECODER', decoder);
            if (decoder.failOnCorruptedImage) {
                throw new Error('Transfer Syntax 1.2.840.10008.1.2.4.90: JP2 image is corrupted');
            }
            const decoded = decoder.tiles[0].items;
            return decoded.buffer;
        }
        case '1.2.840.10008.1.2':
        case '1.2.840.10008.1.2.1':
            return buffer;
        default:
            console.warn(`unknown transfer-syntax: ${transferSyntax}`);
            return buffer;
    }
}

function rescale(rescaleIntercept: number, rescaleSlope: number, bytes: number, data: ArrayBuffer, signed: boolean, mask: number, signBit: number): boolean {
    if (
        rescaleIntercept !== undefined && rescaleSlope !== undefined
        && ((rescaleIntercept != 0.0) || (rescaleSlope != 1.0))
    ) {
        const iarray = (signed) ? (
            (bytes === 1) ? new Int8Array(data):
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        ) : (
            (bytes === 1) ? new Uint8Array(data) :
                (bytes === 2) ? new Uint16Array(data) :
                    (bytes === 4) ? new Uint32Array(data) :
                        null
        );
        const oarray = (
            (bytes === 1) ? new Int8Array(data) :
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        );
        const tmin = (bytes === 1) ? -0x80 :
            (bytes === 2) ? -0x8000 :
                (bytes === 4) ? -0x80000000 :
                    0;
        const tmax = (bytes === 1) ? 0x7f :
            (bytes === 2) ? 0x7fff :
                (bytes === 4) ? 0x7fffffff :
                    0;
        if (iarray != null && oarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (((iarray[i] & mask) ^ signBit) - signBit) * rescaleSlope + rescaleIntercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (iarray[i] & mask) * rescaleSlope + rescaleIntercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            }
            return true;
        } else {
            console.log('UNSUPPORTED WORD SIZE IN RESCALE');
            return signed;
        }
    } else {
        const iarray = (signed) ? (
            (bytes === 1) ? new Int8Array(data) :
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        ) : (
            (bytes === 1) ? new Uint8Array(data) :
                (bytes === 2) ? new Uint16Array(data) :
                    (bytes === 4) ? new Uint32Array(data) :
                        null
        );
        if (iarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = ((iarray[i] & mask) ^ signBit) - signBit;
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = iarray[i] & mask;
                }
            }
        } else {
            console.log('UNSUPPORTED WORD SIZE IN MASKING');
        }
        return signed
    }
}

export interface DicomImg extends Img {
    signed: boolean;
    originalSigned: boolean;
    windowed: boolean;
    frames: number;
    rows: number;
    cols: number;
    bytes: number;
    windowCenter: number;
    windowWidth: number;
    mask: number;
    signBit: number;
    unsignedMask: number;
    samplesPerPixel: number;
    photometricInterpretation: string;
    planarConfiguration: number; 
    modality: string;
    sizes: [number, number][];
    pixelSpacing: [number, number];
    sortKey: number[];
    rescaleType?: string;
}

export function makeDicom(id: string, byteArray: ArrayBuffer): DicomImg {
    const dataSet = parseDicom(new Uint8Array(byteArray))
        , bytes = dataSet.uint16('x00280100') >> 3
        , windowed = true
        , windowCenter = dataSet.string('x00281050') // *FIXME* change to "intString"
        , windowWidth = dataSet.string('x00281051')
        , minPixel = dataSet.uint16('x00280106') | 0
        , maxPixel = dataSet.uint16('x00280107') | 0
        , highBit = Math.pow(2, dataSet.uint16('x00280101')) | 0
        , samplesPerPixel =  dataSet.uint16('x00280002') | 0
        , photometricInterpretation = dataSet.string('x00280004') || 'MONOCHROME2'
        , modality = dataSet.string('x00080060')
        , planarConfiguration = dataSet.uint16('x00280006') | 0
        , rescaleIntercept = dataSet.floatString('x00281052')
        , rescaleSlope = dataSet.floatString('x00281053')
        , signBit = highBit >> 1
        , mask = highBit - 1
        , originalSigned = (dataSet.uint16('x00280103') === 1)
        , rows = dataSet.uint16('x00280010') | 0
        , cols = dataSet.uint16('x00280011') | 0
        , transferSyntax = dataSet.string('x00020010')
        , sizes = [] as [number, number][]
        , dataSize = [] as number[]
        , pixelSpacing = [dataSet.floatString('x00280030', 1), dataSet.floatString('x00280030', 0)] as [number, number] 
        , x1 = dataSet.floatString('x00200037', 0)
        , y1 = dataSet.floatString('x00200037', 1)
        , z1 = dataSet.floatString('x00200037', 2)
        , x2 = dataSet.floatString('x00200037', 3)
        , y2 = dataSet.floatString('x00200037', 4)
        , z2 = dataSet.floatString('x00200037', 5)
        , rescaleType = dataSet.string('x00281054')
        //, modalityLut = dataSet.elements['x00283000']
        ;

    //if (modalityLut) {
    //    console.warn('Modality LUT', modalityLut);
    //}

    const v1 = x1 * x1 + x2 * x2;
    const v2 = y1 * y1 + y2 * y2;
    const v3 = z1 * z1 + z2 * z2;

    const sortIndex = (v1 <= v2 && v1 <= v3) ? 0 :
        (v2 <= v1 && v2 <= v3) ? 1 :
        (v3 <= v1 && v3 <= v2) ? 2 : -1;

    const sortKey = [(sortIndex >= 0) ? (-dataSet.floatString('x00200032', sortIndex)) : dataSet.intString('x00200013')];
 
    let frames = dataSet.intString('x00280008');

    if (!frames) {
        frames = 1;
    }

    const len = rows * cols * bytes * samplesPerPixel;
    const data: ArrayBuffer[] = [];

    let signed = originalSigned;
    for (let i = 0; i < frames; ++i) {
        const px = getPixelData(dataSet, transferSyntax, bytes, len, i, frames);
        signed = rescale(rescaleIntercept, rescaleSlope, bytes, px, originalSigned, mask, signBit) || signed;
        data.push(px);
        dataSize.push(px.byteLength);
        sizes.push([cols, rows]);
    }

    let windowCenterInt = 0;
    let windowWidthInt = 0;

    if (windowWidth && windowCenter) {
        windowCenterInt = parseInt(windowCenter);
        windowWidthInt = parseInt(windowWidth);
    } 
    if (!(windowWidthInt && windowCenterInt)) { // Fix for 0±0 images.
        windowCenterInt = (maxPixel - minPixel) / 2;
        windowWidthInt = maxPixel - minPixel;
    }

    return {
        id,
        iType: IType.Dicom,
        signed,
        originalSigned,
        frames,
        rows,
        cols,
        bytes,
        windowed,
        windowCenter: windowCenterInt,
        windowWidth: windowWidthInt,
        mask: mask|0,
        signBit: signBit|0,
        unsignedMask: (signBit - 1)|0,
        samplesPerPixel,
        photometricInterpretation,
        modality,
        planarConfiguration,
        data,
        sizes,
        caption: '',
        dataSize,
        pixelSpacing,
        sortKey,
        rescaleType
    }
}

export function dicomCompatible(d1: DicomImg, d2: DicomImg): boolean {
    return (d1.bytes === d2.bytes) &&
    (d1.mask === d2.mask) &&
    (d1.samplesPerPixel === d2.samplesPerPixel) &&
    (d1.photometricInterpretation === d2.photometricInterpretation) &&
    (d1.planarConfiguration === d2.planarConfiguration)
}

export function addDicom(dicom: DicomImg, byteArray: ArrayBuffer): DicomImg {
    const dataSet = parseDicom(new Uint8Array(byteArray))
        //, pixel_data_element = data_set.elements.x7fe00010
        , samplesPerPixel =  dataSet.uint16('x00280002') | 0
        , photometricInterpretation = dataSet.string('x00280004') || "MONOCHROME2"
        , planarConfiguration = dataSet.uint16('x00280006') | 0
        , rows = dataSet.uint16('x00280010') | 0
        , cols = dataSet.uint16('x00280011') | 0
        , bytes = dataSet.uint16('x00280100') >> 3
        , highBit = Math.pow(2, dataSet.uint16('x00280101'))|0
        , mask = (highBit - 1)|0
        //, sign_bit = high_bit >> 1
        //, signed = (data_set.intString('x00280103') === 1)
        //, min_pixel = data_set.uint16('x00280106') | 0
        //, max_pixel = data_set.uint16('x00280107') | 0
        , rescaleIntercept = dataSet.floatString('x00281052')
        , rescaleSlope = dataSet.floatString('x00281053')
        , transferSyntax = dataSet.string('x00020010')
        , x1 = dataSet.floatString('x00200037', 0)
        , y1 = dataSet.floatString('x00200037', 1)
        , z1 = dataSet.floatString('x00200037', 2)
        , x2 = dataSet.floatString('x00200037', 3)
        , y2 = dataSet.floatString('x00200037', 4)
        , z2 = dataSet.floatString('x00200037', 5)
        ;

    const v1 = x1 * x1 + x2 * x2;
    const v2 = y1 * y1 + y2 * y2;
    const v3 = z1 * z1 + z2 * z2;

    const sortIndex = (v1 <= v2 && v1 <= v3) ? 0 :
        (v2 <= v1 && v2 <= v3) ? 1 :
        (v3 <= v1 && v3 <= v2) ? 2 : -1;

    const sortKey = (sortIndex >= 0) ? (-dataSet.floatString('x00200032', sortIndex)) : dataSet.intString('x00200013');

    let frames = dataSet.intString('x00280008');

    let valid = true;
    if (dicom.bytes !== bytes) {
        console.log("bytes mismatch", dicom.bytes, "<>", bytes);
        valid = false;
    }
    if (dicom.mask !== mask) {
        console.log("bitmask mismatch", dicom.mask, "<>", mask);
        valid = false;
    }
    if (dicom.samplesPerPixel !== samplesPerPixel) {
        console.log("samples per pixel mismatch", dicom.samplesPerPixel, "<>", samplesPerPixel);
        valid = false;
    }
    if (dicom.photometricInterpretation !== photometricInterpretation) {
        console.log("photometric interpretation mismatch",
            dicom.photometricInterpretation, "<>", photometricInterpretation);
        valid = false;
    }
    if (dicom.planarConfiguration !== planarConfiguration) {
        console.log("planar configuration mismatch", dicom.planarConfiguration, "<>", planarConfiguration);
        valid = false;
    }
    if (!valid) {
        return dicom;
    }

    if (!frames) {
        frames = 1;
    }

    const len = rows * cols * bytes * samplesPerPixel;

    for (let i = 0; i < frames; ++i) {
        const px = getPixelData(dataSet, transferSyntax, bytes, len, i, frames);
        rescale(rescaleIntercept, rescaleSlope, bytes, px, dicom.originalSigned, dicom.mask, dicom.signBit);
        dicom.data.push(px);
        dicom.dataSize.push(px.byteLength);
        dicom.sizes.push([cols, rows]);
    }

    dicom.frames += frames;
    dicom.sortKey.push(sortKey);
    return dicom; 
} 

export function dicomMinMax(dicom: DicomImg, index: number): void {
    const thisData = dicom.data[index];

    if (thisData == null) {
        throw('THIS_DATA is null');
    }
    const inData = new Uint8Array(thisData)
        , count = (dicom.cols * dicom.rows)
        ;
    let src = 0, min, max;

    if (dicom.signed) {
        max = dicom.mask;
        min = dicom.unsignedMask;
    } else {
        max = 0;
        min = dicom.mask;
    }

    for (let i = 0; i < count; ++i) {
        let p = inData[src + dicom.bytes - 1];
        for (let d = dicom.bytes - 2; d >= 0; --d) {
            p <<= 8;
            p += inData[src + d];
        }
        src += dicom.bytes;

        if (dicom.signed) {
            if (p & dicom.signBit) {
                p &= dicom.unsignedMask;
            } else {
                p = dicom.signBit + (p & dicom.unsignedMask);
            }
        } else {
            p &= dicom.mask;
        }

        if (p > max) {
            max = p;
        }
        if (p < min) {
            min = p;
        }
    }

    dicom.windowCenter = (min + max) / 2.0;
    dicom.windowWidth = max - min;
}

export function sortKeys(d: DicomImg): void {
    const indexes = new Array(d.sortKey.length);
    for (let i = 0; i < d.sortKey.length; ++i) {
        indexes[i] = i;
    }
    indexes.sort((a, b) => d.sortKey[a] - d.sortKey[b]);
    d.sortKey = indexes;
}


export class DicomRenderer implements Renderer {
    //private readonly heap: ArrayBuffer;
    //private readonly asm: (pmin: number, pmax: number, pscl: number, n: number) => void;
    private readonly renderer: RenderFn;
    private canvas?: Canvas;

    public index: number;
    public brightness: number;
    public contrast: number;
    public readonly img: DicomImg;

    public constructor(img: DicomImg) {
        const n = Math.max.apply(this, img.sizes.map(([x, y]): number => x * y));
        if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 1 &&
            !img.signed
        ) {
            console.log(`${img.caption}: 8bit unsigned inverted`);
            //this.asm = rmod.render8inverted;
            this.renderer = renderFns.render8inverted(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            !img.signed
        ) {
            console.log(`${img.caption}: 16bit unsigned inverted`);
            //this.asm = rmod.render16inverted_unsigned;
            this.renderer = renderFns.render16inverted(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            img.signed
        ) {
            console.log(`${img.caption}: 16bit signed inverted`);
            //this.asm = rmod.render16inverted_signed;
            this.renderer = renderFns.render16signedInverted(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 1 &&
            !img.signed
        ) {
            console.log(`${img.caption}: 8bit unsigned`);
            //this.asm = rmod.render8;
            this.renderer = renderFns.render8(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            !img.signed
        ) {
            console.log(`${img.caption}: 16bit unsigned`);
            //this.asm = rmod.render16unsigned;
            this.renderer = renderFns.render16(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            img.signed
        ) {
            console.log(`${img.caption}: 16bit signed`);
            //this.asm = rmod.render16signed;
            this.renderer = renderFns.render16signed(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'RGB' &&
            img.samplesPerPixel === 3 &&
            img.bytes === 1 &&
            !img.signed)
        {
            console.log(`${img.caption}: 8bit rgb`);
            //this.asm = rmod.render8rgb;
            this.renderer = renderFns.render8rgb(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else {
            console.log(
                'UNSUPPORTED: ', img.photometricInterpretation,
                'SPP: ', img.samplesPerPixel,
                'BYTES:', img.bytes,
                'SIGNED:', img.signed
            );
            //this.asm = rmod.unsupported;
            this.renderer = renderFns.unsupported(new ArrayBuffer(4 * n));
        }
        this.img = img;
        this.index = 0;
        this.brightness = img.windowCenter;
        this.contrast = img.windowWidth;
    }

    public destroy(): void {
        //this.img.data = [];
    }

    async render(): Promise<void> {
        if (!this.canvas) {
            this.canvas = createCanvas(this.img.cols, this.img.rows);
        }
        const context = this.canvas.getContext('2d', {alpha: false});
        this.realRender(context);
    }

    //private act = 0;
    //private cnt = 0;

    private async realRender(context: CanvasRenderingContext2D): Promise<void> {
        const pmin = this.brightness - 0.5 - (this.contrast - 1.0) / 2.0
            , pmax = this.brightness - 0.5 + (this.contrast - 1.0) / 2.0
            , pscl = 255.0 / (pmax - pmin)
            , [w, h] = this.img.sizes[this.index]
            , count = w * h
            ;
        const thisData = this.img.data[this.index];
        if (thisData != null) {
            //console.log("render", thisData.byteLength, w * h * this.img.bytes * this.img.samplesPerPixel);
            //const t0 = performance.now();
            context.imageSmoothingEnabled = false;
            context.putImageData(createImageData(this.renderer(pmin, pmax, pscl, count, thisData), w, h), 0, 0);
            //new Uint8Array(this.heap, 4 * count).set(new Uint8Array(thisData));
            //this.asm(pmin, pmax, pscl, count);
            //context.putImageData(createImageData(new Uint8ClampedArray(this.heap, 0, 4 * count), w, h), 0, 0);
            //const t1 = performance.now();
            //this.act += (t1 - t0);
            //++this.cnt;
            //console.log(this.act / this.cnt, 'avg render time');
        } else {
            context.clearRect(0, 0, w, h);
            context.font = '1.6rem "Noto Sans"';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = '#0f72c3';
            context.fillText('Loading image ' + (this.index + 1) + ' ...', w / 2.0, h / 2.0);
        }
    }

    private async renderCanvas(): Promise<Canvas> {
        const canvas = createCanvas(this.img.cols, this.img.rows);
        const context = canvas.getContext('2d', {alpha: false});
        if (context == null) {
            throw 'hidden CONTEXT is null';
        }
        if (this.contrast <= 0) {
            dicomMinMax(this.img, this.index);
            this.brightness = this.img.windowCenter;
            this.contrast = this.img.windowWidth;
        }
        await this.realRender(context);
        return canvas;
    }

    private async renderExact(width: number, height: number): Promise<Canvas> {
        const dstCanvas = createCanvas(width, height);
        const context = dstCanvas.getContext('2d', {alpha: false});
        const srcCanvas = await this.renderCanvas();
        context.drawImage(srcCanvas, 0, 0, srcCanvas.width, srcCanvas.height, 0, 0, dstCanvas.width, dstCanvas.height);
        return dstCanvas;
    }

    public async renderSized({height, width} : {height?: number, width?:number}): Promise<Canvas> {
        if (height !== undefined && width !== undefined) {
            return await this.renderExact(width, height);
        } else if (height !== undefined) {
            return await this.renderExact(height * this.img.cols / this.img.rows, height);
        } else if (width !== undefined) {
            return await this.renderExact(width, width * this.img.rows / this.img.cols);
        } else {
            return await this.renderCanvas();
        }
    }

    public async renderThumbnail(): Promise<ImageData> {
        const dstCanvas = createCanvas(120 * this.img.cols / this.img.rows, 120);
        const context = dstCanvas.getContext('2d', {alpha: false});
        const srcCanvas = await this.renderCanvas();
        context.drawImage(srcCanvas, 0, 0, srcCanvas.width, srcCanvas.height, 0, 0, dstCanvas.width, dstCanvas.height);
        return context.getImageData(0, 0, dstCanvas.width, dstCanvas.height);
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        //context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.fillStyle = 'black';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        if (this.canvas) {
            context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            context.imageSmoothingEnabled = false;
            context.drawImage(this.canvas, 0, 0);
            //    Math.floor(context.canvas.width / 2 - this.canvas.width / 2),
            //    Math.floor(context.canvas.height / 2 - this.canvas.height / 2)
            //);
        }
    }

    public async load(
        begin: () => Promise<void>,
        frame: (image: Img, frame: number) => Promise<ArrayBuffer>, 
        end: () => Promise<void>,
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        let renderPromise = Promise.resolve();
        await begin();
        this.img.data.length = this.img.frames;
        let j = this.index;
        for (let i = 0; i < this.img.frames; ++i) {
            if (j >= this.img.frames) {
                j %= this.img.frames;
            }
            const k = this.img.sortKey[j];
            const f = await frame(this.img, k);
            const s = this.img.sizes[j];
            const l = s[0] * s[1] * this.img.bytes * this.img.samplesPerPixel;
            if (f.byteLength !== l) {
                alert(`byteLength ${f.byteLength} <> ${l} expected length`);
                console.error(`byteLength ${f.byteLength} <> ${l} expected length`);
            }
            this.img.data[j] = f;
            if (j === this.index) {
                renderPromise = render();
            }
            progress(((i + 2) * 100.0) / (this.img.frames + 1));
            ++j;
        }
        await end();
        await renderPromise;

    }

    public convexMean({y0, y1, f}: {y0: number, y1: number, f: (y: number) => {x0: number, x1: number}}): {mean?: number, stddev?: number} {
        const buffer = this.img.data[this.index];
        if (buffer) {
            let data;
            if (this.img.samplesPerPixel === 1) {
                if (this.img.bytes === 1) {
                    if (this.img.signed) {
                        data = new Int8Array(buffer);
                    } else {
                        data = new Uint8Array(buffer);
                    }
                } else if (this.img.bytes === 2) {
                    if (this.img.signed) {
                        data = new Int16Array(buffer);
                    } else {
                        data = new Uint16Array(buffer);
                    }
                }
            }
            if (data) {
                let sum = 0;
                let count = 0;
                let sq_sum = 0;
                if (y1 < y0) {
                    [y0, y1] = [y1, y0];
                }
                y0 = Math.floor(y0 + 0.5);
                y1 = Math.floor(y1 + 0.5);
                for (let y = y0; y < y1; ++y) {
                    let {x0, x1} = f(y + 0.5);
                    if (x1 < x0) {
                        [x0, x1] = [x1, x0];
                    }
                    x0 = Math.floor(x0 + 0.5);
                    x1 = Math.floor(x1 + 0.5);
                    for (let x = x0; x < x1; ++x) {
                        //const cxt = this.canvas?.getContext('2d');
                        //if (cxt) {
                        //    cxt.fillStyle = "white";
                        //    cxt.fillRect(x, y, 1, 1);
                        //}
                        const v = data[this.img.cols * y + x];
                        sum += v;
                        sq_sum += v * v;
                        ++count;
                    } 
                }
                const mean = sum / count;
                const stddev = Math.sqrt(sq_sum / count - mean * mean);
                return {mean, stddev};
            }
        }
        return {};
    }
}

export function isDicomImg(img: Img): img is DicomImg {
    return img.iType === IType.Dicom;
}

export function isDicomRenderer(renderer?: Renderer): renderer is DicomRenderer {
    return (renderer && typeof renderer === 'object' && isDicomImg(renderer.img)) ?? false;
}

registerRendererType({
    name: 'DicomRenderer',
    hasMime(mime: string): boolean {
        return mime === 'application/dicom';  
    },
    makeImg(id: string, buffer: ArrayBuffer): DicomImg {
        return makeDicom(id, buffer);
    },
    addImg(dicom: DicomImg, buffer: ArrayBuffer): DicomImg {   
        addDicom(dicom, buffer);
        return dicom;
    },
    isThis(resource: Img) {
        return resource.iType === IType.Dicom;
    },
    makeRenderer(resource: DicomImg): Renderer {
        return new DicomRenderer(resource);
    }
});
