import { mkNode } from './utils';
import { faCat, faPaw } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from 'question-base';
import { version } from '@gen/version';

const shortVersion = version.match(/^p4b-(\d+\.\d+\.\d+)/)?.[1];
const debugVersion = version.match(/^\S+\s+DEBUG\s+\[\S+\s+\S+\s+\S+\s+\S+\s+(\d+):(\d+):\d+\s+\S+\]$/);

console.warn('ROBOT_CAT', shortVersion, debugVersion);

export class RobotCat {
    private controlPanel: ControlPanel;
    private catButton?: HTMLButtonElement;
    private iconOff: HTMLSpanElement;
    private iconOn: HTMLSpanElement;
    private interval?: number;

    private readonly handleInterval = () => {
        switch (Math.floor(Math.random() * 2)) {
            case 0:
                console.log('ROBOT_CAT next');
                document.getElementById('next-button')?.click();
                break;
            case 1:
                console.log('ROBOT_CAT previous');
                document.getElementById('prev-button')?.click();
                break;
        }
    };

    private readonly handleCatButton = () => {
        console.log('ROBOT_CAT');
        if (this.interval === undefined) {
            this.interval = window.setInterval(this.handleInterval, 100);
            this.catButton?.replaceChild(this.iconOn, this.iconOff);
        } else {
            window.clearInterval(this.interval);
            this.interval = undefined;
            this.catButton?.replaceChild(this.iconOff, this.iconOn);
        }
    };

    constructor(controlPanel: ControlPanel) {
        this.controlPanel = controlPanel;
        this.iconOff = mkNode('icon', {icon: faCat});
        this.iconOn = mkNode('icon', {icon: faPaw});
        if (debugVersion) {
            this.catButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    this.iconOff,
                    mkNode('span', {className: 'app-button-text', children: [
                        mkNode('text', {text: `${shortVersion}.${debugVersion[1]}.${debugVersion[2]}`}),
                    ]})
                ]
            });
            this.controlPanel.add(this.catButton)
            this.catButton.addEventListener('click', this.handleCatButton);
        }
    }

    public async destroy(): Promise<void> {
        if (this.interval !== undefined) {
            window.clearInterval(this.interval);
            this.interval = undefined;
        }
        if (this.catButton) {
            this.catButton.removeEventListener('click', this.handleCatButton);
            this.controlPanel.remove(this.catButton);
            this.catButton = undefined;
        }
    }
}
